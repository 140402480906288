// @ts-nocheck
//REACT
import React, { useEffect, useReducer, useState } from 'react';

//COMPONENT
import DialogCustomer from './DialogCustomer';
import DialogCustomerListDownload from './DialogCustomerListDownload';
import Customer from './Customer';
//CONTEXT
import { CustomerContext } from '../../contexts';

//REDUCER
import { customerReducer, customerInfoInit } from '../../reducers';

import { getBannerData } from '../../api';
import BannerPopup from './BannerPopup';

const CustomerWrapper: React.FC = () => {
	const [state, dispatch] = useReducer(customerReducer, customerInfoInit);

	// banner alert
	const [bannerList, setBannerList] = useState([]);

	useEffect(() => {
		// api
		initBannerData();
	}, []);

	const initBannerData = async () => {
		try {
			const res = await getBannerData();
			setBannerList(res);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<CustomerContext.Provider value={{ state, dispatch }}>
			<Customer />
			<DialogCustomer />
			<DialogCustomerListDownload />
			{/*<DialogEvent />*/}

			{/* 배너 알럿 */}

			{bannerList.length > 0 && (
				<>
					{bannerList.map((banner, index) => (
						<BannerPopup key={banner.advIdx} bannerList={banner} index={index} />
					))}
				</>
			)}
		</CustomerContext.Provider>
	);
};

export default CustomerWrapper;
