//REACT
import React from 'react';

//STYLE
import styled, { css } from 'styled-components';

interface IJellyButton {
	children: any;
	shape?: 'round' | 'square';
	bgType?: 'white' | 'jellybook';
	shadow?: boolean;
	prepend?: any;
	append?: any;
	[propName: string]: any;
}

const JellyButton: React.FC<IJellyButton> = ({ children, ...props }: IJellyButton) => {
	const Prepend = props.prepend;
	const Append = props.append;
	return (
		<Button {...props}>
			{Prepend}
			{children}
			{Append}
		</Button>
	);
};

//TODO: prepend , append 영역 조절
const Button = styled.button`
  display:inline-flex;
  align-items:center;
  height:37px;
  font-size: 14px;
  font-weight: bold;
  transform: skew(0.05deg);
  cursor: pointer;
  &:disabled{
    cursor : not-allowed;
  }
  ${({ shadow }: IJellyButton) => (shadow ? 'box-shadow: 0 2px 6px 0 rgba(28, 21, 56, 0.24);' : '')}

  ${({ shape, bgType }: IJellyButton) => {
		if (shape === 'round') {
			if (bgType === 'jellybook') {
				return css`
					background-color: #ed7c89;
					color: #fff;
					min-width: 125px;
					border-radius: 37px;
				`;
			} else {
				return css`
					background-image: #fff;
					color: #ed7c89;
					border: solid 1px #ed7c89;
					min-width: 125px;
					border-radius: 37px;
				`;
			}
		} else {
			if (bgType === 'jellybook') {
				return css`
					border-radius: 3px;
					min-width: 76px;
					background-color: #ed7c89;
					color: #ffffff;
				`;
			} else {
				return css`
					border-radius: 3px;
					min-width: 76px;
					background-color: #fff;
					border: solid 1px #ed7c89;
					color: #ed7c89;
				`;
			}
		}
  }}
  /* ${(props: any) => (props.shape === 'round' ? 'min-width:125px;border-radius:37px;' : 'border-radius:3px;min-width:76px;')}
  ${(props: any) =>
		props.bgType === 'jellybook'
			? ` 
          background-color:#ed7c89;
          color:#fff;
        `
			: ` 
          background-color:#fff;
          border:solid 1px #eadfff;
          color:#c2b2de;
        `} */
  justify-content : ${({ prepend, append }: IJellyButton) => (prepend || append ? 'space-between' : 'center')};
  ${({ prepend, append }: IJellyButton) => {
		if (prepend) {
			return css`
				padding: 0 18px 0 6px;
			`;
		} else if (append) {
			return css`
				padding: 0 6px 0 18px;
			`;
		} else {
			return css`
				padding: 0 18px;
			`;
		}
  }}
  & + button {
    margin-left: ${(props: any) => (props.shape === 'round' ? '15px' : '5px')}
  }
`;
export default JellyButton;
