//IMPORT
import { History } from 'history';
import axios from 'axios';
import { CareAlert } from '../style/jellybook-component';
import { getMasterOption } from '../store/auth/authAction';

//TYPE
interface IAjaxOption {
	url: string;
	method: 'GET' | 'POST' | 'PUT' | 'DELETE';
	headers?: any;
	params?: any;
	data?: any;
	baseURL?: string;
	cancelToken?: any;
}

interface IOnlyNumInputPayload {
	value: string;
	limitLen?: number;
	changeEvent: () => void;
}

export const getToken = () => localStorage.getItem('token') || sessionStorage.getItem('token');

export const getApiToken = () => {
	const { isMaster, branchList, pccIndex } = getMasterOption();
	if (isMaster && branchList.length - 1 >= pccIndex) {
		return branchList[pccIndex].branchToken;
	}
	return localStorage.getItem('token') || sessionStorage.getItem('token');
};

export const networkError = (msg: string = '잠시후에 시도해주세요.'): void => {
	// export const networkError = (msg: string = '긴급 서버 점검 안내\n점검 소요시간:\n06월 28일 09시 ~ 12시\n불편함을 드려 대단히 죄송합니다.'): void => {
	CareAlert.info({
		content: msg,
	});
};

export const isLogin = () => (getToken() ? true : false);

export const httpError = (history: History, code: number): void => {
	switch (code) {
		case 401:
			CareAlert.info({
				content: '로그인 후 이용해주세요.',
				ok: () => history.push('/login'),
			});
			break;
		case 403:
			history.push('/403');
			break;
		case 404:
			history.push('/404');
			break;
		case 500:
			networkError();
			break;
		default:
			networkError('잠시후 다시 시도해주세요.');
			// networkError('긴급 서버 점검 안내\n점검 소요시간:\n06월 28일 09시 ~ 12시\n불편함을 드려 대단히 죄송합니다.');
			break;
	}
};
export const amountCommas = (number: number): string => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const fileToBase64 = (file: File) => {
	return new Promise((resolve: (response: string | ArrayBuffer | null) => void) => {
		const fileReader = new FileReader();
		fileReader.readAsDataURL(file);
		fileReader.onload = () => resolve(fileReader.result);
	});
};

export const objToFormData = (obj: any) => {
	const formData = new FormData();
	Object.keys(obj).map((item) => formData.append(item, obj[item]));
	return formData;
};

export const $http = (option: IAjaxOption) => (useToken: boolean) => {
	if (useToken) {
		option.headers = {
			...option.headers,
			'authoried-token': getToken(),
		};
	}
	return axios(option);
};

export const autoHyphen = (txt: string, use: 'PHONE' | 'BIRTH') => {
	if (use === 'PHONE') {
		return txt
			.replace(/[^0-9]/g, '')
			.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3')
			.replace('--', '-');
	} else {
		return txt
			.replace(/[^0-9]/g, '')
			.replace(/(^02.{0}|^01.{1}|[0-9]{4})([0-9]+)([0-9]{2})/, '$1-$2-$3')
			.replace('--', '-');
	}
};

export const onlyNumberInput = (payload: IOnlyNumInputPayload) => {
	const { value, limitLen, changeEvent } = payload;
	const onlyNumReg = /^[0-9]+$/g;
	if (value.match(onlyNumReg)) {
		if ((limitLen && value.length <= limitLen) || !limitLen) {
			changeEvent();
		}
	} else if (value === '') {
		changeEvent();
	}
};
