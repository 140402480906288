import styled from 'styled-components';
import { Select } from 'antd';

interface IInputProps {
	width?: number;
	type?: string;
	disabled?: boolean;
}

interface IJellyFormProps {
	inline?: boolean;
	vertical?: boolean;
}

interface IContainerTopProps {
	justifyContent?: string;
}

interface IDimmedProps {
	visible: boolean;
	bgColor?: 'dark' | 'white';
	[propName: string]: any;
}

export const PALLETE = {
	KEY: '#e55863',
};

export const JellyForm = styled.ul``;

export const JellyFormItem = styled.li`
	display: flex;
	align-items: center;
	min-height: 60px;
	padding: 12px 0;
	border-bottom: 1px solid #f6f1fe;
	&:nth-of-type(1) {
		padding-top: 0;
		min-height: 42px;
	}
`;

export const JellyVerticalFormItem = styled.div`
	${(props: IJellyFormProps) =>
		props.inline
			? `
    margin-right: 27px;
    &:last-child {
      margin-right: 0;
    }
    `
			: `
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    `}
	min-height: 60px;
	width: 100%;
`;

export const JellyFormLabel = styled.label`
	${(props: IJellyFormProps) => (props.vertical ? 'margin-bottom: 10px;' : '')}
	display: flex;
	flex: 0 0 77px;
	font-size: 12px;
	color: #8b8b8b;
	font-weight: 700;
	&.required {
		&:after {
			content: '';
			display: block;
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background-color: #ec4c4c;
			margin-left: 3px;
		}
	}
`;

export const JellyFormErrorMessage = styled.div`
	color: #ec4c4c;
	margin-top: 7px;
	font-size: 12px;
	&:before {
		content: '';
		display: inline-block;
		margin-right: 5px;
		width: 3px;
		height: 3px;
		border-radius: 3px;
		background-color: #ec4c4c;
	}
`;

export const JellyInfoWrap = styled.div`
	color: #151515;
	flex: 1;
	.ant-select-open .ant-select-arrow-icon img {
		transform: rotate(180deg);
	}
	.ant-select-arrow .ant-select-arrow-icon img {
		-webkit-transition: -webkit-transform 0.3s;
		transition: -webkit-transform 0.3s;
		transition: transform 0.3s;
		transition: transform 0.3s, -webkit-transform 0.3s;
	}
`;

export const JellyInput = styled.input`
	${(props: IInputProps) => (props.type === 'password' ? 'font-family: sans-serif' : '')}
	${(props: IInputProps) =>
		props.disabled
			? `
    background-color: #f6f5f7;
    color: #bababa;
    border: 1px solid #eee;
  `
			: 'border: 1px solid #e6e6e6;'}
  border-radius: 3px;

	padding: 0 10px;
	width: 100%;
	height: 36px;
	&::placeholder {
		color: #d5d5d5;
	}
`;

export const JellySelect = styled(Select)`
	&.ant-select {
		${(props: IInputProps) => (props.width ? `width: ${props.width}px;` : 'width: 100%')};
		height: 100%;
	}
	.ant-select-selection {
		height: 100%;
		border-color: #e6e6e6;
	}
	.ant-select-selection__rendered {
		height: 100%;
	}
	.ant-select-selection__placeholder {
		font-weight: 300;
	}
`;

export const JellyContainer = styled.section`
	border-radius: 5px;
	background-color: #fff;
`;

export const JellyContainerTop = styled.div`
	height: 77px;
	border-bottom: 1px solid #f6f1fe;
	display: flex;
	padding: 0 25px;
	${(props: IContainerTopProps) => (props.justifyContent ? `justify-content: ${props.justifyContent};` : '')}
	align-items: center;
`;

export const CountBox = styled.div`
	color: #151515;
	display: flex;
	align-items: center;
	transform: skew(0.05deg);
	margin-right: 25px;
`;

export const CamBox = styled.div`
	color: #151515;
	display: flex;
	align-items: center;
	transform: skew(0.05deg);
	margin-right: 25px;
	position: absolute;
	right: 0;
`;

export const CamOnBox = styled.div`
	color: #fff;
	display: flex;
	align-items: center;
	transform: skew(0.05deg);
	height: 36px;
	border-radius: 37px;
	background-color: #ed7c89;
	padding: 0 25px 0 35px;
	position: relative;

	@keyframes flash {
		0% {
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

	&:before {
		position: absolute;
		content: '';
		display: block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: #fff;
		top: 50%;
		left: 15px;
		transform: translateY(-50%);
		animation: flash 1s infinite;
	}
`;

export const CamOffBox = styled.div`
	color: #fff;
	display: flex;
	align-items: center;
	transform: skew(0.05deg);
	height: 36px;
	border-radius: 37px;
	background-color: #b4b4b4;
	padding: 0 25px 0 25px;
`;

export const CountNumber = styled.div`
	font-size: 22px;
	font-weight: bold;
	margin-right: 5px;
	color: #bababa;
`;

export const CountTxt = styled.div`
	font-size: 12px;
	color: #bebebe;
`;

export const TableOptionWrap = styled.div`
	display: flex;
	align-items: center;
`;

export const Dimmed = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: ${(props: IDimmedProps) => (props.bgColor ? 'rgba(255, 255, 255, 0.6)' : 'rgba(24, 21, 45, 0.3)')};
	z-index: 30;
	justify-content: center;
	align-items: center;
	display: ${(props: IDimmedProps) => (props.visible ? 'flex' : 'none')};
`;

// 2023.01.05 캠모달 항목추가
export const UiDivision = styled.div`
	display: flex;
	gap: 40px;

	> ul {
		flex: 1;
		position: relative;
		width: 340px;

		.ant-select {
			width: 100% !important;
		}
	}
`;

export const FormTitle = styled.p`
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 20px;
`;

export const JellyFormItemGroup = styled.li`
	padding: 12px 0;
	border-bottom: 1px solid #f6f1fe;
	&:first-of-type {
		padding-top: 0;
		> div {
			&:first-of-type {
				padding-top: 0;
				min-height: 42px;
			}
		}
	}
`;
export const JellyFormItemGroupItem = styled.div`
	display: flex;
	align-items: center;
	min-height: 60px;
	padding: 6px 0;
`;

export const NoneData = styled.p`
	font-size: 14px;
`;
