/**
 * @file 모든 페이지에서 사용하는 전역 상태 store
 * @author 고종현
 * @version : 0.0.2(2022.06.09)
 */

import { storeInitializer } from '../config/configStore';

/**
 * @description 모든 페이지에서 두루 쓰는 전역 상태 초기값 지정
 */
export const authInitialState = {
	//관리자 여부
	isMaster: false,
	branchList: [],
	pccIndex: 0,
	isOurHome: false,
};

/**
 * @description mainPage store hook
 */
const useAuthStore = storeInitializer(authInitialState, 'authStore');

//store hook export
export default useAuthStore;
