//REACT
import { useContext, createContext, Dispatch } from "react";

//TYPE
import { TGlobalActions, IGlobalState } from "../reducers/globalReducer";

interface ICustomerContext {
  globalState: IGlobalState;
  globalDispatch: Dispatch<TGlobalActions>;
}
export const GlobalContext = createContext<ICustomerContext | undefined>(undefined);

export function useJellybookGlobal() {
  const state = useContext(GlobalContext);
  if (!state) throw new Error("jellybook Global Context를 찾을 수 없습니다🤷‍♀️");
  return state;
}
