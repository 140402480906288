//REACT
import React, { useEffect, useState, useRef } from "react";

//STYLE
import styled from "styled-components";
import { JellyDialog, JellyButton } from "../../components";

//CONTEXT
import { useCustomerInfo } from "../../contexts";

//API
import { downloadCustomerList } from "../../api";

//TYPE
import { IDownloadPeriod } from "../../api/customer";

const DialogCustomerListDownload: React.FC = () => {
  const [filePw, setFilePw] = useState<string>("");
  const [pwValidation, setPwValidation] = useState<boolean>(false);
  const [downloadFile, setDownloadfile] = useState<IDownloadPeriod>({
    excelLink: "",
    excelName: ""
  });
  const {
    state: { downloadVisible, searchVal, checkin },
    dispatch
  } = useCustomerInfo();

  //TODO: type 찾아서 수정
  const pwInput: any = useRef();
  const downloadBtn: any = useRef();
  const handleSetPW = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilePw(e.currentTarget.value);
  };
  const handleDownloadFile = async () => {
    const LENGTH_LIMIT = {
      MIN: 8,
      MAX: 16
    };
    if (filePw.length >= LENGTH_LIMIT.MIN && filePw.length <= LENGTH_LIMIT.MAX) {
      const {
        period: { excelLink, excelName }
      } = await downloadCustomerList({
        excelPass: filePw,
        searchVal,
        checkin
      });
      setDownloadfile({
        excelLink,
        excelName
      });
      dispatch({ type: "DOWNLOAD_HIDE" });
    } else {
      setPwValidation(true);
    }
  };

  const handlePressEnterOnInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") handleDownloadFile();
  };

  useEffect(() => {
    if (!downloadVisible) {
      setPwValidation(false);
      setFilePw("");
    } else {
      pwInput.current.focus();
    }
  }, [downloadVisible]);

  useEffect(() => {
    if (downloadFile.excelLink !== "") {
      downloadBtn.current.click();
    }
  }, [downloadFile]);

  return (
    <JellyDialog
      visible={downloadVisible}
      onPressClose={() => dispatch({ type: "DOWNLOAD_HIDE" })}
      title="비밀번호 설정하기"
      buttons={[
        <JellyButton bgType="jellybook" key={1} onClick={handleDownloadFile}>
          확인
        </JellyButton>
      ]}
    >
      <DownloadLabel>엑셀파일 비밀번호</DownloadLabel>
      <DownloadInput
        ref={pwInput}
        type="password"
        placeholder="엑셀파일 비밀번호를 입력해주세요."
        title="엑셀파일 비밀번호를 입력해주세요."
        onChange={handleSetPW}
        onKeyPress={handlePressEnterOnInput}
        value={filePw}
      />
      {pwValidation && <DownloadErrorMessage>8~16자리 비밀번호를 설정해주세요.</DownloadErrorMessage>}
      <DownloadBtn href={downloadFile.excelLink} download={downloadFile.excelName} ref={downloadBtn} />
    </JellyDialog>
  );
};

const DownloadLabel = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #8b8b8b;
  margin-bottom: 10px;
`;
const DownloadInput = styled.input`
  border-radius: 3px;
  border: solid 1px #e6e6e6;
  color: #8b8b8b;
  font-family: sans-serif;
  padding: 0 8px;
  height: 36px;
  width: 100%;
  font-weight: 400;

  &::placeholder {
    font-family: "NanumSquare", sans-serif;
    color: #d5d5d5;
  }
`;
const DownloadErrorMessage = styled.div`
  color: #ec4c4c;
  margin-top: 7px;
  font-size: 12px;

  &:before {
    vertical-align: middle;
    content: "";
    display: inline-block;
    margin-right: 5px;
    width: 3px;
    height: 3px;
    border-radius: 3px;
    background-color: #ec4c4c;
  }
`;
const DownloadBtn = styled.a`
  position: absolute;
  left: -9999px;
`;
export default DialogCustomerListDownload;
