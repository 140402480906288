// REACT
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

// COMPONENT
import { JellyDialog, JellyButton, JellyAlert } from '../../components';

// STYLE
import styled from 'styled-components';
import arrowIcon from '../../assets/images/ic-arrow-dw.svg';
import { Select } from 'antd';
import {
	JellyInput,
	JellySelect,
	JellyForm,
	JellyFormItem,
	JellyFormLabel,
	JellyFormErrorMessage,
	JellyInfoWrap,
	UiDivision,
	FormTitle,
	JellyFormItemGroup,
	JellyFormItemGroupItem,
	NoneData,
} from '../../style';

// API
import { getCamData, getCustomerCheckinList, getCustomerBabyList, getCustomerInfoDetail, disconnectCam, connectCam, getAnotherParty } from '../../api';

// UTIL
import { httpError } from '../../services';
import moment from 'moment';

// TYPE
import { ICustomerBabyList, ICheckinMomList, getCamList, IPartyInfo } from '../../api/cam';

const { Option } = Select;

type TUid = string | undefined;

interface IMomInfo {
	phone: string;
	birth: string;
}

interface IDialogProps {
	visible: boolean;
	camUid: string;
	momUid?: string;
	camName: string;
	onPressClose: () => void;
	onSuccess: (status: string) => void;
}

interface IRequiredStatus {
	momUid: boolean;
	babyUid: boolean;
}

interface ISelectBaby {
	uid?: string;
	connect: string;
}

const replaceAt = (target: string, fIndex: number, lIndex: number, replacement: string) => {
	if (fIndex >= target.length || fIndex < 0) {
		return target;
	}

	return target.substring(0, fIndex) + replacement + target.substring(lIndex + 1);
};

const DialogCamConnect: React.FC<IDialogProps> = (props) => {
	const history = useHistory();
	const { visible, onPressClose, camUid, onSuccess, camName } = props;
	const [isConnectCam, setIsConnectCam] = useState<boolean>(true);
	const [connectConfirmVisible, setConnectConfirmVisible] = useState<boolean>(false);
	const [checkinList, setCheckinList] = useState<ICheckinMomList[]>([]);
	const [babyList, setBabyList] = useState<ICustomerBabyList[]>([]);
	const [requiredStatus, setRequiredStatus] = useState<IRequiredStatus>({ momUid: true, babyUid: true });
	const [babyErrMessage, setBabyErrMessage] = useState<string>('');
	const [momUid, setMomUid] = useState<TUid>(undefined);
	const [selectedBaby, setSelectedBaby] = useState<ISelectBaby>({
		uid: undefined,
		connect: 'N',
	});
	const [momInfo, setMomInfo] = useState<IMomInfo>({ phone: '', birth: '' });
	const [isUpdate, setIsUpdate] = useState<boolean>(false); //업데이트 체크
	const [partyInfo, setPartyInfo] = useState<IPartyInfo[]>([]);

	const resetDialog = () => {
		setMomInfo({ phone: '', birth: '' });
		setIsConnectCam(true);
		setMomUid(undefined);
		setSelectedBaby({
			uid: undefined,
			connect: 'N',
		});
		setRequiredStatus({ momUid: true, babyUid: true });
		setCheckinList([]);
		setBabyList([]);
		setPartyInfo([]);
	};

	const renderConnectData = useCallback(async (camUid) => {
		// 캠 정보
		try {
			const {
				period: { momUid, momPhone, momBirh, babyUid, partyInfo },
			} = await getCamData(camUid);
			setMomUid(momUid);
			setSelectedBaby({
				uid: babyUid,
				connect: 'Y',
			});
			setMomInfo({
				phone: momPhone,
				birth: moment(momBirh).format('YYMMDD'),
			});
			setPartyInfo([...partyInfo]);
		} catch ({ code }) {
			httpError(history, code);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderCustomerData = useCallback(async (uid) => {
		// 연결중인 산모 데이터
		try {
			const {
				period: { phone, birth, partyInfo },
			} = await getCustomerInfoDetail({ customerUid: uid });
			setMomInfo({ phone, birth });
			setPartyInfo([...partyInfo]);
		} catch ({ code }) {
			httpError(history, code);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderCustomerList = useCallback(async () => {
		// 입실중인 산모 리스트
		try {
			const { period } = await getCustomerCheckinList();
			setCheckinList([...period]);
		} catch ({ code }) {
			httpError(history, code);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderBabyList = useCallback(async (uid) => {
		// 산모의 아기 리스트
		try {
			const { period } = await getCustomerBabyList(uid);
			//아기 리스트 저장
			setBabyList([...period]);
			//첫번째 아기 정보 저장 - 고객정보 등록 시 아기이름1 필수값 추가 시 주석해제
			/*
      setSelectedBaby({
        uid: period[0].babyUid,
        connect: period[0].connect
      });
      */
		} catch ({ code }) {
			httpError(history, code);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//캠 연결 해제
	const disconnectAndCheckout = async (momCheckin: string) => {
		try {
			if (isConnectCam) {
				const { success, message } = await disconnectCam(camUid, momCheckin);
				if (success === 1) {
					onSuccess(message);
					onPressClose();
					window.location.reload();
				}
			}
		} catch ({ code }) {
			httpError(history, code);
		}
		setConnectConfirmVisible(false);
	};

	const handleClickDisConnect = async () => {
		// 캠 연결 해제 시 멘트 조건 체크
		const connectedBaby = babyList.filter(({ connect }) => connect === 'Y');
		//if (babyList.length <= 1 || (babyList.length > 1 && connectedBaby.length !== babyList.length)) {
		if (connectedBaby.length <= 1) {
			//캠연결수량이 1개이상일 경우
			setConnectConfirmVisible(true);
		} else {
			disconnectAndCheckout('Y');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	//연결 저장
	const handleClickSubmit = async () => {
		if (isUpdate == true) {
			//업데이트 중일때 이중 저장 방지
			return false;
		}
		setIsUpdate(true);
		// 캠 연결
		try {
			if (!momUid) {
				setRequiredStatus({ momUid: false, babyUid: true });
				setIsUpdate(false);
			} else if (!selectedBaby.uid) {
				setBabyErrMessage('아기이름을 선택해주세요.');
				setRequiredStatus({ momUid: true, babyUid: false });
				setIsUpdate(false);
			} /*else if (selectedBaby.connect === "Y") {
        setBabyErrMessage("이미 캠에 연결중인 아기입니다.");
        setRequiredStatus({ momUid: true, babyUid: false });
      } */ else {
				// 휴대폰 번호로 이미 연결 되어 있는 산모인지 체크하기
				const {
					period: { partyYn },
				} = await getAnotherParty(momInfo.phone);

				if (partyYn === 'N') {
					// 기존 연결된 적이 없는 경우 산모캠 등록
					const { success, message } = await connectCam({ cameraUid: camUid, momUid, babyUid: selectedBaby.uid });
					if (success === 1) {
						setIsUpdate(false);
						onSuccess(message);
						onPressClose();
						window.location.reload();
					}
				} else {
					const selBabyUid = selectedBaby.uid;
					// 기존 연결된 적이 있는 경우 알럿
					JellyAlert.confirm({
						content: (
							<p>
								이미 다른 캠에 등록되어 있습니다.
								<br />
								기존 연결을 해지하고 산모로 등록하시겠습니까?
							</p>
						),
						ok() {
							camConnect(momUid, selBabyUid);
						},
						cancel() {
							onPressClose();
							window.location.reload();
						},
					});
				}
			}
		} catch ({ code }) {
			httpError(history, code);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	const camConnect = async (seleMomUid: string, selBabyUid: string) => {
		const { success, message } = await connectCam({ cameraUid: camUid, momUid: seleMomUid, babyUid: selBabyUid });
		if (success === 1) {
			setIsUpdate(false);
			onSuccess(message);
			onPressClose();
			window.location.reload();
		}
	};

	useEffect(() => {
		// 산모 선택 시 산모 데이터, 아기 리스트 요청
		if (visible) {
			renderCustomerData(momUid);
			renderBabyList(momUid);
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [momUid, renderCustomerData, renderBabyList]);

	useEffect(() => {
		// 팝업 오픈 시 산모, 아기 리스트 요청
		if (visible) {
			renderCustomerList();
			if (props.momUid) {
				// 연결된 산모 정보 요청
				renderConnectData(camUid);
				setIsConnectCam(true);
			} else {
				setIsConnectCam(false);
			}
		} else {
			resetDialog();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [visible, renderCustomerList, renderConnectData]);

	return (
		<JellyDialog
			title={camName}
			visible={visible}
			onPressClose={onPressClose}
			buttons={[
				<ConnectButton key={1} bgType="white" onClick={handleClickDisConnect} isConnect={isConnectCam}>
					연결해제
				</ConnectButton>,
				<JellyButton key={2} bgType="jellybook" onClick={handleClickSubmit}>
					저장
				</JellyButton>,
			]}
			dialogWidth={'auto'}
		>
			<JellyDialog
				title="캠 연결 해제"
				visible={connectConfirmVisible}
				onPressClose={() => setConnectConfirmVisible(false)}
				buttons={[
					<JellyButton key={1} bgType="white" onClick={() => disconnectAndCheckout('N')}>
						퇴실
					</JellyButton>,
					<JellyButton key={2} bgType="jellybook" onClick={() => disconnectAndCheckout('Y')}>
						연결해제
					</JellyButton>,
				]}
			>
				{/* 해당 산모님을 미입실 상태로 변경하시겠습니까? */}
				해당 산모님이 퇴실하신 경우 "퇴실", 퇴실하지 않고 캠 연결만 해제하시려는 경우 "연결해제"를 선택해주세요.
			</JellyDialog>
			<UiDivision>
				<JellyForm>
					<FormTitle>산모 정보</FormTitle>
					<JellyFormItem>
						<JellyFormLabel className="required">산모이름</JellyFormLabel>
						<JellyInfoWrap>
							<Select
								value={momUid}
								optionLabelProp="label"
								//width={213}
								style={{
									width: '213px',
								}}
								placeholder="선택해주세요."
								suffixIcon={
									<i aria-label="icon: down" className="anticon anticon-down ant-select-arrow-icon">
										<img src={arrowIcon} alt="산모 이름 선택" />
									</i>
								}
								onChange={(momUid: any) => {
									setSelectedBaby({ uid: undefined, connect: 'N' });
									setRequiredStatus({ babyUid: true, momUid: true });
									setMomUid(momUid);
								}}
							>
								{checkinList.map((data: ICheckinMomList) => (
									<Option value={data.customerUid} key={data.customerUid} label={data.customerName} data-connect={data.connect}>
										<BabyOption>
											{data.customerName} {data.connect === 'Y' && <ConnectState>ON</ConnectState>}
										</BabyOption>
									</Option>
								))}

								{/* {checkinList.map((data: any) => (
                  <Option value={data.customerUid} key={data.customerUid}>
                    {data.customerName}
                  </Option>
                ))} */}
							</Select>
							{!requiredStatus.momUid && <JellyFormErrorMessage>산모이름을 선택해주세요.</JellyFormErrorMessage>}
						</JellyInfoWrap>
					</JellyFormItem>
					<JellyFormItem>
						<JellyFormLabel>전화번호</JellyFormLabel>
						<JellyInfoWrap>
							<JellyInput name="phone" type="text" value={momInfo.phone} readOnly placeholder="010-0000-0000" />
						</JellyInfoWrap>
					</JellyFormItem>
					<JellyFormItem>
						<JellyFormLabel>생년월일</JellyFormLabel>
						<JellyInfoWrap>
							<JellyInput name="birth" type="text" value={momInfo.birth} readOnly placeholder="YYMMDD" />
						</JellyInfoWrap>
					</JellyFormItem>
					<JellyFormItem>
						<JellyFormLabel className="required">아기이름</JellyFormLabel>
						<JellyInfoWrap>
							<Select
								value={selectedBaby.uid}
								style={{
									width: '213px',
								}}
								optionLabelProp="label"
								placeholder="선택해주세요."
								suffixIcon={
									<i aria-label="icon: down" className="anticon anticon-down ant-select-arrow-icon">
										<img src={arrowIcon} alt="아기이름 선택" />
									</i>
								}
								onChange={(babyUid: any, option: any) => {
									setSelectedBaby({
										uid: babyUid,
										connect: option.props['data-connect'],
									});
									setRequiredStatus({ ...requiredStatus, babyUid: true });
								}}
							>
								{babyList.map((data: ICustomerBabyList) => (
									<Option value={data.babyUid} key={data.babyUid} label={data.babyName} data-connect={data.connect}>
										<BabyOption>
											{data.babyName} {data.connect === 'Y' && <ConnectState>ON</ConnectState>}
										</BabyOption>
									</Option>
								))}
							</Select>
							{!requiredStatus.babyUid && <JellyFormErrorMessage>{babyErrMessage}</JellyFormErrorMessage>}
						</JellyInfoWrap>
					</JellyFormItem>
				</JellyForm>

				{/* 2023.01.05 캠모달 항목추가 */}
				{/* 20230104 jhtest 가족정보 추가 */}
				<JellyForm>
					<FormTitle>가족 정보</FormTitle>
					{partyInfo.length > 0 ? (
						partyInfo.map((data: IPartyInfo, index) => (
							<React.Fragment key={index}>
								<JellyFormItemGroup>
									<JellyFormItemGroupItem>
										<JellyFormLabel>가족이름</JellyFormLabel>
										<JellyInfoWrap>
											<JellyInput name="famName" type="text" value={data.name} readOnly placeholder="test" />
										</JellyInfoWrap>
									</JellyFormItemGroupItem>
									<JellyFormItemGroupItem>
										<JellyFormLabel>가족과의 관계</JellyFormLabel>
										<JellyInfoWrap>
											<JellyInput name="famRelation" type="text" value={data.relation} readOnly placeholder="test" />
										</JellyInfoWrap>
									</JellyFormItemGroupItem>
									<JellyFormItemGroupItem>
										<JellyFormLabel>가족 전화번호</JellyFormLabel>
										<JellyInfoWrap>
											<JellyInput name="famPhone" type="text" value={replaceAt(data.phone, 4, 7, '****')} readOnly placeholder="test" />
										</JellyInfoWrap>
									</JellyFormItemGroupItem>
								</JellyFormItemGroup>
							</React.Fragment>
						))
					) : (
						<NoneData>등록된 가족이 없습니다.</NoneData>
					)}
				</JellyForm>
			</UiDivision>
		</JellyDialog>
	);
};

const ConnectButton = styled(JellyButton)`
	& {
		${(props: any) => (props.isConnect ? '' : 'border-color: #eee; color: #bababa;')}
	}
`;

const BabyOption = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const ConnectState = styled.div`
	width: 27px;
	height: 16px;
	border-radius: 8px;
	border: 1px solid #e55863;
	font-size: 10px;
	font-weight: bold;
	line-height: 16px;
	text-align: center;
	color: #e55863;
`;

export default DialogCamConnect;
