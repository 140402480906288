//REACT
import { useContext, createContext, Dispatch } from "react";

//TYPE
import { TCustomerInfoActions, ICustomerState } from "../reducers/customerReducer";

interface ICustomerContext {
  state: ICustomerState;
  dispatch: Dispatch<TCustomerInfoActions>;
}
export const CustomerContext = createContext<ICustomerContext | undefined>(undefined);

export function useCustomerInfo() {
  const state = useContext(CustomerContext);
  if (!state) throw new Error("Customer Context를 찾을 수 없습니다🤷‍♀️");
  return state;
}
