import { customActionInitializer } from '../config/configStore';
import useAuthStore from './authStore';
import { getMasterOptionAPI } from '../../api';
import { getToken } from '../../utils';

const { setState, getState } = customActionInitializer(useAuthStore);

export const loadMasterOption = () => {
	// @ts-ignore
	getMasterOptionAPI().then((response: any) => {
		setState((draft: any) => {
			const { masterYn, branchList, ourHomeYn } = response.period;
			const isMaster = masterYn === 'Y';
			draft.isMaster = isMaster;
			if (isMaster) {
				const masterInfo = { branchName: '마스터', branchToken: getToken() };
				draft.branchList = [masterInfo, ...branchList];
			} else {
				draft.branchList = [];
			}

			const isOurHome = ourHomeYn === 'Y';
			draft.isOurHome = isOurHome;

			console.log('마스터 정보', draft.isOurHome, ourHomeYn, isOurHome);
		}, '관리자 권한 설정');
	});
};
export const setMasterOption = (isMaster: boolean, branchList: any) => {
	setState((draft: any) => {
		draft.isMaster = isMaster;
		draft.branchList = branchList || [];
	}, '관리자 권한 설정');
};

export const setPccIndex = (pccIndex: number) => {
	setState((draft: any) => {
		draft.pccIndex = pccIndex;
	}, '산후조리원 변경');
};

export const getMasterOption = () => {
	return {
		isMaster: getState().isMaster,
		branchList: getState().branchList,
		pccIndex: getState().pccIndex,
		isOurHome: getState().isOurHome,
	};
};
