// @ts-nocheck

//REACT
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';

//STYLE
import { Modal } from 'antd';
import styled from 'styled-components';

const { info, confirm } = Modal;

export const JellyAlert = ({ children, visible, callback, buttons, contentStyle, containerStyle, ...props }) => {
	const [alertVisible, setAlertnVisible] = useState(false);
	//TODO: type
	const btnRef = useRef(null);

	useEffect(() => {
		setAlertnVisible(visible);
	}, [visible]);

	useLayoutEffect(() => {
		if (visible && btnRef && !buttons) {
			btnRef.current.focus();
		}
	});

	return (
		<AlertBox visible={alertVisible} className="alertbox">
			<Alert {...props}>
				<AlertContent style={contentStyle && contentStyle}>{children}</AlertContent>
				{!buttons ? (
					<AlertButton ref={btnRef} onClick={callback}>
						확인
					</AlertButton>
				) : (
					<ButtonWrap>{buttons.map((item) => item.render())}</ButtonWrap>
				)}
			</Alert>
		</AlertBox>
	);
};

JellyAlert.info = ({ content, ok }) => {
	info({
		content,
		className: 'jellybook-alert jellybook-alert--info',
		okText: '확인',
		onOk() {
			if (ok) {
				ok();
			}
		},
	});
};

JellyAlert.confirm = ({ content, ok, cancel, okText = '확인', cancelText = '취소' }) => {
	confirm({
		content,
		onOk() {
			if (ok) {
				ok();
			}
		},
		onCancel() {
			if (cancel) {
				cancel();
			}
		},
		okText,
		cancelText,
		className: 'jellybook-alert jellybook-alert--confirm',
	});
};
const Alert = styled.div`
	min-width: 270px;
	border-radius: 8px;
	box-shadow: 0 0 25px 0 rgba(28, 21, 56, 0.24);
	background-color: #fff;
	position: relative;
	text-align: center;
	overflow: hidden;
	color: #151515;
`;

const AlertContent = styled.div`
	font-size: 14px;
	font-weight: 400;
	padding: 20px 34px;
	transform: skew(0.05deg);
`;
const AlertButton = styled.button`
	left: 0;
	bottom: 0;
	height: 40px;
	width: 100%;
	border-top: 1px solid #eee;
	font-weight: bold;
	transform: skew(0.05deg);
	cursor: pointer;
`;
const ButtonWrap = styled.div`
	width: 100%;
	border-top: 1px solid #eee;
	display: flex;
	overflow: hidden;
	& > * {
		flex: 1;
	}
`;

export const AlertBox = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: ${(props) => (props.bgColor ? 'rgba(255, 255, 255, 0.6)' : 'rgba(24, 21, 45, 0.3)')};
	z-index: 30;
	opacity: 0;
	justify-content: center;
	align-items: center;
	display: ${(props) => (props.visible ? 'flex' : 'none')};
	overflow-y: auto;

	&:last-of-type {
		z-index: 31;
		opacity: 1;
	}
`;

export default JellyAlert;
