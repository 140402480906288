// @ts-nocheck

import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { getNoticeDetail, getNoticeList } from '../../api/notice';
import { JellyContainer } from '../../style';
import { CareLoading } from '../../style/jellybook-component';
import searchIcon from '../../assets/images/ic-search.svg';
import keywordDeleteIcon from '../../assets/images/ic-search-close.svg';
import { Pagination, Table } from 'antd';
import NodataCustomer from '../../assets/images/ic-cus-list-none.svg';
import { JellyButton, JellyDialog } from '../../components';

const Notice = () => {
	const [noticeList, setNoticeList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [checkKeyword, setCheckKeyword] = useState('');
	const [visibleSearchKeywordDelete, setVisibleSearchKeywordDelete] = useState(false);
	const [pageInfo, setPageInfo] = useState({
		page: 1,
		pageView: 10,
	});
	const [noticeDetail, setNoticeDetail] = useState(null);

	const [modal, setModal] = useState({
		visible: false,
	});

	useEffect(() => {
		getList('', pageInfo);
	}, []);

	useEffect(() => {
		getList(checkKeyword, pageInfo);
	}, [pageInfo]);

	useEffect(() => {
		if (!modal.visible) setNoticeDetail(null);
	}, [modal]);

	// 공지리스트 api
	const getList = async (title, pages) => {
		const res = await getNoticeList({ subject: title, page: pages.page, pageView: pages.pageView });
		setNoticeList(res.rowList);
		setTotalCount(res.jellybookBanner);

		setIsLoading(false);
	};

	// 공지상세 api
	const getDetail = async (uid) => {
		const res = await getNoticeDetail({ uid: uid });
		setNoticeDetail(res);
		setModal({
			...modal,
			visible: true,
		});
	};

	// 검색어 삭제버튼 활성화
	const handleChangeKeyword = (e) => {
		setCheckKeyword(e.currentTarget.value);
		if (e.currentTarget.value.length >= 1) {
			setVisibleSearchKeywordDelete(true);
		} else {
			setVisibleSearchKeywordDelete(false);
		}
	};

	// 검색
	const handleSearch = useCallback(
		(value) => {
			getList(value, pageInfo);
		},
		[pageInfo],
	);

	// 타이틀 검색 엔터 이벤트
	const handleKeyEventSearch = (e) => {
		if (e.key === 'Enter') {
			handleSearch(e.target.value);
		}
	};

	// 키워드 제거
	const handleDeleteKeyword = () => {
		setVisibleSearchKeywordDelete(false);
		setCheckKeyword('');
	};

	// 리스트 컬럼
	const columns = [
		{
			title: 'NO',
			name: 'no',
			dataIndex: 'no',
			key: 'no',
		},
		{
			title: '제목',
			name: 'subject',
			key: 'subject',
			render: (data) => <ViewDetail onClick={() => handleViewDetail(data.uid)}>{data.subject}</ViewDetail>,
		},
		// {
		// 	title: '상태',
		// 	name: 'adStatus',
		// 	dataIndex: 'adStatus',
		// 	key: 'adStatus',
		// 	render: (data) => (
		// 		<PopupStatus className={data === 3 ? 'colorRed' : data === 1 ? 'colorBlue' : ''}>
		// 			{data === 1 ? '팝업표시' : data === 2 ? '일시중지' : data === 3 ? '팝업종료' : '-'}
		// 		</PopupStatus>
		// 	),
		// },
		{
			title: '등록일',
			name: 'regDate',
			dataIndex: 'regDate',
			key: 'regDate',
			render: (data) => <DateText>{data}</DateText>,
		},
	];

	// 모달닫기
	const handleCloseDialog = () => {
		setModal({
			...modal,
			visible: false,
		});
	};

	const handleViewDetail = (index) => {
		getDetail(index);
	};

	return (
		<>
			<JellyContainer>
				<CareLoading visible={isLoading} fill />
				<NoticeTop>
					<CountBox>
						<CountNumber>{totalCount}</CountNumber>
						<CountTxt>개의 공지가 있습니다.</CountTxt>
					</CountBox>
					<SearchWrap>
						<SearchInput
							type="text"
							placeholder="제목을 입력해주세요."
							onChange={handleChangeKeyword}
							onKeyPress={handleKeyEventSearch}
							value={checkKeyword}
							autoComplete={'off'}
						/>
						{visibleSearchKeywordDelete && <SearchKeywordReset onClick={handleDeleteKeyword} />}
					</SearchWrap>
				</NoticeTop>
				<TableWrap>
					<Table
						columns={columns}
						dataSource={noticeList}
						pagination={false}
						rowKey={(record) => record.no}
						locale={{
							emptyText: (
								<NoDataWrap>
									<NoDataImg src={NodataCustomer} alt="No data" title="No data" />
									<NoDataTxt>검색된 공지사항이 없습니다.</NoDataTxt>
								</NoDataWrap>
							),
						}}
					/>
					<Pagination
						defaultCurrent={1}
						current={pageInfo.page}
						total={totalCount}
						className="jellybook"
						onChange={(idx) => {
							setPageInfo({
								page: idx,
							});
						}}
					/>
				</TableWrap>
			</JellyContainer>

			{/* 상세 모달 */}
			<JellyDialog
				dialogWidth={'800px'}
				buttons={<JellyButton onClick={handleCloseDialog}>닫기</JellyButton>}
				visible={modal.visible}
				onPressClose={handleCloseDialog}
				title="공지사항"
			>
				{noticeDetail && (
					<>
						<NoticeInfoForm>
							<NoticeInfoFormItem>
								<NoticeInfoLabel>제목</NoticeInfoLabel>
								<NoticeInfoWrap>{noticeDetail.subject}</NoticeInfoWrap>
							</NoticeInfoFormItem>

							<NoticeInfoFormItem>
								<NoticeInfoLabel>등록일</NoticeInfoLabel>
								<NoticeInfoWrap>{noticeDetail.regDate}</NoticeInfoWrap>
							</NoticeInfoFormItem>
							{noticeDetail.linkUrl && (
								<NoticeInfoFormItem>
									<NoticeInfoLabel>등록링크</NoticeInfoLabel>
									<a href={noticeDetail.linkUrl} rel="noopener noreferrer" target="_blank">
										{noticeDetail.linkUrl}
									</a>
								</NoticeInfoFormItem>
							)}

							<NoticeInfoFormItem>
								<NoticeInfoLabel>본문</NoticeInfoLabel>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									{noticeDetail.bannerImgUrl && (
										<>
											{' '}
											<img src={noticeDetail.bannerImgUrl} style={{ marginBottom: 20, width: '100%', height: ' auto' }} />
										</>
									)}
									<NoticeInfoWrap>{noticeDetail.text}</NoticeInfoWrap>
								</div>
							</NoticeInfoFormItem>
						</NoticeInfoForm>
					</>
				)}
			</JellyDialog>
		</>
	);
};

const NoticeTop = styled.div`
	height: 77px;
	border-bottom: 1px solid #f6f1fe;
	display: flex;
	padding: 0 25px;
	justify-content: space-between;
	align-items: center;
`;
const CountBox = styled.div`
	color: #151515;
	display: flex;
	align-items: center;
	transform: skew(0.05deg);
`;
const CountNumber = styled.div`
	font-size: 22px;
	font-weight: bold;
	margin-right: 5px;
`;
const CountTxt = styled.div`
	font-size: 12px;
`;
const SearchWrap = styled.div`
	height: 100%;
	flex: 1;
	padding: 0 87px 0 52px;
	align-items: center;
	position: relative;
	&:before {
		position: absolute;
		left: 23px;
		top: 50%;
		transform: translateY(-50%);
		display: block;
		content: '';
		width: 20px;
		height: 20px;
		background-image: url(${searchIcon});
		background-size: 17px;
		background-repeat: no-repeat;
		background-position: center;
	}
`;
const SearchInput = styled.input`
	height: 100%;
	width: 100%;
	outline: none;
	transform: skew(1deg);
	color: #151515;
	font-weight: bold;
	::placeholder {
		color: #d5d5d5;
	}
`;

const SearchKeywordReset = styled.button`
	width: 15px;
	height: 15px;
	position: absolute;
	right: 22px;
	top: 50%;
	transform: translateY(-50%);
	background-image: url(${keywordDeleteIcon});
	background-size: 13px;
	background-repeat: no-repeat;
	background-position: left center;
	cursor: pointer;
`;
const TableWrap = styled.div`
	padding: 0 25px 61px 25px;
	position: relative;
`;
const NoDataWrap = styled.div`
	height: 530px;
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	align-items: center;
`;
const ViewDetail = styled.a`
	text-decoration: underline;
	color: #ed7c89;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	width: 800px;
`;

const NoDataImg = styled.img`
	margin-bottom: 24px;
`;
const NoDataTxt = styled.p`
	color: #bababa;
	font-weight: 400;
	transform: skew(0.05deg);
`;
const NoticeInfoForm = styled.ul``;
const NoticeInfoFormItem = styled.li`
	display: flex;
	align-items: center;
	min-height: 60px;
	padding: 12px 0;
	border-bottom: 1px solid #f6f1fe;

	&:nth-of-type(1) {
		padding-top: 0;
		min-height: 42px;
	}
`;
const NoticeInfoLabel = styled.label`
	display: flex;
	flex: 0 0 77px;
	font-size: 12px;
	color: #8b8b8b;
	font-weight: 700;

	&.required {
		&:after {
			content: '';
			display: block;
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background-color: #ec4c4c;
			margin-left: 3px;
		}
	}
`;
const NoticeInfoWrap = styled.div`
	color: #151515;
	flex: 1;
`;
const PopupStatus = styled.span`
	white-space: nowrap;
	&.colorRed {
		color: #ec4c4c;
	}
	&.colorBlue {
		color: #00a2ff;
	}
`;
const DateText = styled.span`
	white-space: nowrap;
`;
export default Notice;
