import styled, { css } from 'styled-components';

export const CamLoading = styled.img`
	&.ant-spin-dot {
		width: 40px;
		height: 40px;
		margin: 0 !important;
		transform: translate(-50%, 100%);
		// transform: translate(-50%, -50%);
	}
`;

export const CamBox = styled.div`
	width: 290px;
	/* overflow: hidden; */
	border: 1px solid #eeeeee;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;
	border-radius: 4px;
`;

export const CamImgWrap = styled.div`
	position: relative;
	width: 100%;
	height: calc(290px * 0.625);
	background-color: #fafafa;
	box-sizing: border-box;
	background: url(${(props) =>
			props.isMaster === 'N' && props.viewAble === 'N'
				? 'https://iandna-contents.s3.ap-northeast-2.amazonaws.com/ivory/pccAdmin/jelly_closeCam.jpg'
				: '/static/media/ic-no-connect.36cea9aa.svg'})
		no-repeat center;
	${(props) => (props.isConnect && !props.isErr ? 'cursor: pointer;' : '')}
	${(props) => (props.isMaster === 'N' && props.viewAble === 'N' ? 'background-size: cover;' : '')}

	[class*='ant-spin-'] {
		width: 100%;
		height: 100%;
	}
`;

export const CamImg = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

export const CamNumber = styled.div`
	position: absolute;
	top: 0;
	left: 8px;
	width: 68px;
	height: 26px;
	text-align: center;
	color: #fff;
	font-weight: bold;
	line-height: 27px;
	font-size: 11px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	background-color: #b4b4b4;

	&.isCam {
		background-color: #f3a1a7;

		&.isLive {
			background-color: #e74451;
		}
	}
`;

export const CamTitle = styled.div`
	height: 80px;
	padding-top: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: skew(0.03deg);
	cursor: pointer;
	position: relative;
	overflow: hidden;
	border-top: 1px solid #eee;
	color: #b4b4b4;

	&.isMom {
		color: #000;
		background-color: #f3f3f3;

		&.isLive {
			background-color: #e74451;
			color: #fff;
		}
	}
`;

export const PureperdName = styled.div`
	font-size: 16px;
	font-weight: bold;
	color: inherit;
	${(props) => {
		if (props.isConnect && !props.isErr) {
			return css`
				margin-right: 3px;
			`;
		} else {
			return css``;
		}
	}}
`;

export const CamTitleTxt = styled.div`
	font-size: 13px;
	color: inherit;
`;

// 20230104 jhlee 캠 가족 추가 신청 수 추가
export const CamSubTxt = styled.div`
	font-size: 13px;
	color: inherit;
	margin-left: 5px;
`;

// 20230315 ajy 시청시간 디테일 추가
export const DetailTime = styled.div`
	position: absolute;
	display: flex;
	width: 200%;
	left: 0;
	right: 0;
	bottom: 49px;
	overflow: hidden;
	transition: left 0.3s;
	pointer-events: none;
	align-items: flex-end;
	z-index: 2;

	button {
		cursor: pointer;
		pointer-events: all;
	}

	&.open {
		left: -100%;
	}

	> div {
		min-width: 50%;
	}

	.total_time_box {
		padding: 0px;
		display: flex;
		flex-direction: row;
		gap: 0px;
		position: relative;
	}

	.total_info_box {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 0;
		margin: 0 0 0 5px;
		height: 30px;
		background-color: #fff;
		border-radius: 2px;
		padding: 0 8px 0 0;
		border: 1px solid #fff;
		pointer-events: all;

		&.mom {
			width: 115px;

			&.isLive {
				border: 1px solid #f00;

				.type {
					background-color: #f00;

					&:before {
						display: block;
					}
				}
			}
		}

		&.family {
			width: 165px;

			&.isLive {
				border: 1px solid #000;

				.type {
					background-color: #000;

					&:before {
						display: block;
					}
				}
			}
		}

		@keyframes dotFlash {
			0% {
				opacity: 0;
			}
			50% {
				opacity: 1;
			}
			100% {
				opacity: 0;
			}
		}

		.type {
			min-width: 52px;
			font-size: 12px;
			font-weight: bold;
			color: #fff;
			background-color: #b4b4b4;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			height: 30px;
			border-radius: 2px;
			margin-left: -5px;
			padding-left: 20px;
			position: relative;

			&:before {
				content: '';
				display: none;
				position: absolute;
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background-color: #fff;
				top: 50%;
				transform: translateY(-50%);
				left: 8px;
				opacity: 0;
				animation: dotFlash 1s infinite;
			}
		}

		.time {
			margin-left: 6px;
			white-space: nowrap;
			font-size: 10px;
			font-weight: bold;
			color: #000;
			display: flex;
			justify-content: space-between;
			width: 100%;

			.detail_text {
				margin-left: 4px;
			}

			.img_box {
				margin-left: 4px;
				width: 3.3px;
				height: 6.7px;
				position: relative;
				display: inline-block;

				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}
	}

	.family_detail_box {
		background-color: #fff;
		padding: 8px 0;
		pointer-events: all;

		&.isLive {
			.header {
				color: #f00;
			}
		}

		.header {
			display: flex;
			justify-content: space-between;
			color: #b4b4b4;
			font-size: 10px;
			font-weight: 1000;
			padding: 0 8px;

			button {
				padding: 0;

				span {
					font-weight: 1000;
				}
			}

			.img_box {
				margin-right: 4px;
				width: 3.3px;
				height: 6.7px;
				position: relative;
				display: inline-block;

				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}
	}

	.family_list {
		margin-top: 4px;
		position: relative;
		display: flex;
		flex-wrap: wrap;

		li {
			padding: 4px 8px;
			width: 50%;
			display: flex;
			justify-content: space-between;

			&.isLive span.family_type {
				color: #f00;
			}

			span {
				font-size: 10px;
				color: #000;

				&.family_type {
					font-weight: bold;
				}
				&.family_time {
					font-weight: normal;
				}
			}
		}

		span {
			padding: 5px;
			display: flex;
			font-size: 10px;
			font-weight: bold;
		}
	}
`;
