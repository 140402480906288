//REACT
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

//API
import { login } from '../../api';

//TYPE
import { ILoginRequest } from '../../api/login';

//UTIL
import { httpError } from '../../utils';

//STYLE
import styled from 'styled-components';
import { CareAlert, careToast } from '../../style/jellybook-component';

//IMAGE
import logoWithIcon from '../../assets/images/gnb-logo.svg';
import loginIllust from '../../assets/images/login-illust.png';
import loginFormLogo from '../../assets/images/logo-txt.svg';

//CONTEXT
import { useJellybookGlobal } from '../../contexts';

//COMPONENTS
import moment from 'moment';
const Login: React.FC<RouteComponentProps> = ({ history }) => {
	//const history = useHistory();
	const { globalDispatch } = useJellybookGlobal();
	const router = useHistory();
	const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
	const [customerCenterAlertVisible, setCustomerCenterAlertVisible] = useState<boolean>(false);
	const [loginPayload, setLoginPayload] = useState<ILoginRequest>({
		id: '',
		pass: '',
	});

	// 임시점검팝업
	const [maintenance, setMaintenance] = useState<boolean>(true);

	const handleToggleCustomerCenterAlert = (): void => setCustomerCenterAlertVisible(!customerCenterAlertVisible);
	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			if (loginPayload.id.trim().length >= 1 && loginPayload.pass.trim().length >= 1) {
				const {
					period: { success, accessToken, lockPd },
				} = await login(loginPayload);
				if (success === 1) {
					sessionStorage.setItem('token', accessToken);
					globalDispatch({ type: 'LOGIN' });
					// const page = lockPd >= 180 ? '/changePassword' : '/';
					const page = '/';
					//history.push(page);
					router.replace(page);
				} else {
					setVisibleAlert(true);
				}
			} else {
				careToast('아이디 혹은 비밀번호를 입력해주세요.');
			}
		} catch (e) {
			httpError(history, e);
		}
	};

	const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) =>
		setLoginPayload({
			...loginPayload,
			[e.currentTarget.name]: e.currentTarget.value,
		});

	const handleIgnoreCustomerCenterPopup = (): void => {
		const ignoreStamp = {
			expire: moment().add(1, 'd'),
		};
		handleToggleCustomerCenterAlert();
		localStorage.setItem('ignoreCustomerCenterPopup', JSON.stringify(ignoreStamp));
	};
	useEffect(() => {
		const checkCustomerPopup = (): void => {
			const isIgnorePopup = localStorage.getItem('ignoreCustomerCenterPopup');
			if (!isIgnorePopup) {
				setCustomerCenterAlertVisible(true);
			} else {
				const { expire } = JSON.parse(isIgnorePopup);
				if (expire) {
					const duration = moment.duration(moment().diff(expire));
					const hours = duration.asHours();
					if (hours >= 0) {
						setCustomerCenterAlertVisible(true);
						localStorage.removeItem('ignoreCustomerCenterPopup');
					}
				}
			}
		};
		checkCustomerPopup();
	}, []);
	return (
		<LoginWrap>
			<LoginBox>
				<LoginIllustWrap>jellybook</LoginIllustWrap>
				<LoginFormWrap>
					<LoginWelcome title="Welcome to jellybook">
						<LoginWelcomeTxt>Welcome to</LoginWelcomeTxt>
						<img src={loginFormLogo} alt="jellybook" title="jellybook" />
					</LoginWelcome>
					<LoginForm onSubmit={handleSubmit} method="POST">
						<LoginInputWrap>
							<LoginInput name="id" placeholder="id" onChange={(e) => handleChangeInput(e)} />
							<LoginInput name="pass" placeholder="password" type="password" onChange={(e) => handleChangeInput(e)} />
						</LoginInputWrap>
						<LoginSubmit type="submit">Login</LoginSubmit>
					</LoginForm>
				</LoginFormWrap>
			</LoginBox>
			{/* <CareAlert width="auto" visible={maintenance} callback={() => setMaintenance(false)}>
				<img src={maintenanceImg} alt="점검안내" />
				
			</CareAlert> */}
			<CareAlert visible={visibleAlert} callback={() => setVisibleAlert(false)}>
				<AlertStrong>사용자 정보가 올바르지 않습니다.</AlertStrong>
				<AlertText>고객센터로 문의 하십시오.</AlertText>
				<AlertBottom>jellybook 고객센터 070-4616-5990</AlertBottom>
			</CareAlert>
			{/* <JellyAlert
        visible={customerCenterAlertVisible}
        buttons={[
          {
            render: () => (
              <AlertButton key={0} onClick={handleIgnoreCustomerCenterPopup}>
                오늘 다시 보지 않기
              </AlertButton>
            )
          },
          {
            render: () => (
              <AlertButton key={1} onClick={handleToggleCustomerCenterAlert}>
                확인
              </AlertButton>
            )
          }
        ]}
        contentStyle={{
          padding: "30px 0 0 0"
        }}
      >
        <CustomerCenterContent>
          <CustomerCenterTitle>젤리뷰 고객센터 대표번호 공지</CustomerCenterTitle>
          <CustomerCenterImage src={communication} />
          <CustomerCenterMessage>
            앱 2.0으로 업데이트 되었습니다.
            <br />
            다시 CAM 버튼이 홈화면 우측상단으로 변경되었습니다.
            <br />
            새로운 매뉴얼이 좌측 환경설정 밑에 업로드가 되었습니다.
            <br />
            다운로드 받아서 프린트 후 객실에 비치해주세요!
            <br />
            문의사항은 고객센터 070-4616-5991로 연락 부탁드립니다.
          </CustomerCenterMessage>
          <CustomerCenterTimeWrap>
            <CustomerCenterRow>
              <CustomerCenterLabel>고객센터</CustomerCenterLabel>
              <CustomerCenterPhoneNumber>070-4616-5990 / 070-4616-5991</CustomerCenterPhoneNumber>
            </CustomerCenterRow>
            <CustomerCenterRow>
              <CustomerCenterLabel>운영시간</CustomerCenterLabel>
              <CustomerCenterTime>평일 10:00 - 17:00 / 점심 12:30 - 13:30</CustomerCenterTime>
            </CustomerCenterRow>
            <CustomerCenterOff>*토,일요일 및 공휴일은 휴무입니다.</CustomerCenterOff>
          </CustomerCenterTimeWrap>
        </CustomerCenterContent>
      </JellyAlert> */}
		</LoginWrap>
	);
};

const LoginWrap = styled.div`
	display: flex;
	min-width: 820px;
	height: 100%;
	background-color: #f5f4f7;
	align-items: center;
	justify-content: center;
`;

const LoginBox = styled.section`
	width: 820px;
	height: 488px;
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	display: flex;
`;

const LoginIllustWrap = styled.div`
	position: relative;
	width: 450px;
	height: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100%;
	background-image: url(${loginIllust});
	text-indent: -9999px;
	&:before {
		display: block;
		position: absolute;
		top: 30px;
		left: 30px;
		width: 116px;
		height: 27px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: 100%;
		background-image: url(${logoWithIcon});
		content: '';
	}
`;

const LoginFormWrap = styled.div`
	width: 370px;
	height: 100%;
	padding: 101px 60px 0 60px;
	background-color: #fff;
`;

const LoginWelcome = styled.h1`
	font-size: 15px;
	color: #bababa;
	margin-bottom: 51px;
`;
const LoginWelcomeTxt = styled.p`
	margin-bottom: 18px;
`;

const LoginForm = styled.form`
	display: block;
`;

const LoginInputWrap = styled.div`
	margin-bottom: 50px;
`;

const LoginInput = styled.input`
	width: 100%;
	height: 46px;
	padding: 0 15px;
	font-size: 14px;
	border-radius: 3px;
	background-color: #f4f4f4;
	color: #151515;
	transform: skew(0.05deg);
	&:nth-of-type(1) {
		margin-bottom: 15px;
	}
	&[type='password'] {
		font-family: sans-serif;
	}
	::placeholder {
		color: #c2c2c2;
	}
`;

const LoginSubmit = styled.button`
	width: 100%;
	height: 46px;
	border-radius: 3px;
	font-size: 14px;
	color: #fff;
	background-color: #ed7c89;
`;

const AlertStrong = styled.strong`
	font-weight: bold;
	display: block;
	margin-bottom: 10px;
`;
const AlertText = styled.p`
	margin-bottom: 34px;
`;
const AlertBottom = styled.div`
	height: 35px;
	line-height: 35px;
	background-color: #f7f7f7;
	color: #8b8b8b;
	font-size: 12px;
	font-weight: 500;
	border-top: 1px solid #eee;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
`;

const AlertButton = styled.button`
	height: 63px;
	font-size: 14px;
	font-weight: bold;
	border-right: 1px solid #eeeeee;
	&:nth-last-of-type(1) {
		border-right: none;
	}
`;

const CustomerCenterContent = styled.article`
	width: 408px;
	transform: skew(0.04deg);
`;
const CustomerCenterTitle = styled.h2`
	font-size: 15px;
	font-weight: bold;
	color: #151515;
	margin-bottom: 30px;
`;

const CustomerCenterMessage = styled.p`
	font-size: 11px;
	color: #8b8b8b;
	line-height: 1.45;
	margin-bottom: 10px;
`;

const CustomerCenterImage = styled.img`
	margin-bottom: 15px;
`;

const CustomerCenterTimeWrap = styled.div`
	background-color: #f4f4f4;
	text-align: left;
	border-top: 2px solid #eee;
	border-bottom: 2px solid #eee;
	padding: 10px 0 10px 77px;
`;

const CustomerCenterRow = styled.div`
	margin-bottom: 10px;
	&:nth-last-of-type(1) {
		margin-bottom: 15px;
	}
`;

const CustomerCenterLabel = styled.span`
	font-size: 11.5px;
	font-weight: 900;
	margin-right: 10px;
	color: #7e7e7e;
`;

const CustomerCenterPhoneNumber = styled.span`
	font-size: 11.5px;
	color: #e55863;
`;

const CustomerCenterTime = styled(CustomerCenterPhoneNumber)`
	color: #7e7e7e;
`;

const CustomerCenterOff = styled.span`
	font-size: 10.5px;
	color: #7e7e7e;
`;
export default Login;
