//REACT
import React, { useState, useEffect } from "react";

//COMPONENT
import { JellyButton, JellyDialog } from "../../components";

//STYLE
import { ReviewTextarea, ReviewBlindText } from "./style";

interface IDialogBlind {
  visible: boolean;
  [key: string]: any;
}

const DialogBlind: React.FC<IDialogBlind> = ({ visible, rvId, onBlind, onPressClose }) => {
  const [reason, setReason] = useState<string>("");

  const handleSubmit = () => {
    if (reason.trim().length >= 1) {
      onBlind(rvId, reason);
      onPressClose();
    } else {
      JellyDialog.info({
        title: "블라인드 사유",
        content: "블라인드 사유를 입력해주세요."
      });
    }
  };
  useEffect(() => {
    setReason("");
  }, [visible]);
  return (
    <JellyDialog
      title="블라인드 요청"
      visible={visible}
      dialogWidth="1000px"
      onPressClose={onPressClose}
      buttons={[
        <JellyButton key={1} bgType="white" onClick={onPressClose}>
          취소
        </JellyButton>,
        <JellyButton key={2} bgType="jellybook" onClick={handleSubmit}>
          요청완료
        </JellyButton>
      ]}
    >
      <ReviewTextarea
        placeholder="사유를 남겨주세요."
        value={reason}
        onChange={e => setReason(e.target.value)}
      ></ReviewTextarea>
      <ReviewBlindText>
        요청 승인 시 사용자의 리뷰는 블라인드 처리됩니다. 당사는 소비자가 자유롭게 정보를 교류할 수 있는 플랫폼 서비스
        입니다.
        <br />
        법적 분쟁 발생 시 당사에 어떠한 책임도 없음을 알려드립니다.
      </ReviewBlindText>
    </JellyDialog>
  );
};

export default DialogBlind;
