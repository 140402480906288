import styled from "styled-components";
export { default as CareButton } from "./Button";
export { default as CareDialog } from "./Dialog";
export { default as CamDialog } from "./Dialog";
export { default as CareAlert } from "./Alert";
export { default as careToast } from "./Toast";
export { default as CareLoading } from "./Loading";

interface IDimmedProps {
  visible: boolean;
  bgColor?: "dark" | "white";
  [propName: string]: any;
}
export const Dimmed = styled.div`
  position: fixed
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props: IDimmedProps) => (props.bgColor ? "rgba(255, 255, 255, 0.6)" : "rgba(24, 21, 45, 0.3)")};
  z-index: 30;
  justify-content: center;
  align-items: center;
  display: ${(props: IDimmedProps) => (props.visible ? "flex" : "none")};
`;
