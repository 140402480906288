// @ts-nocheck

//REACT
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

//STYLE
import styled from 'styled-components';
import { CareAlert } from '../../../style/jellybook-component';

//IMAGE
import logo from '../../../assets/images/gnb-logo.svg';
import searchIcon from '../../../assets/images/ic-search.svg';
import logoutIcon from '../../../assets/images/ic-logout.svg';
import keywordDeleteIcon from '../../../assets/images/ic-search-close.svg';

//UTIL
import jwt from 'jwt-decode';
import { getToken } from '../../../utils';

//CONTEXT
import { useJellybookGlobal } from '../../../contexts';
import useAuthStore from '../../../store/auth/authStore';
import { loadMasterOption, setPccIndex } from '../../../store/auth/authAction';

import { Select } from 'antd';
import { JellyAlert } from '../../atoms';

interface IJWTPayload {
	id: string;
	branchName: string;
	name: string;
	exp: number;
	branchUid: string;
}

interface IHeaderProps {
	visible: boolean;
}

interface IUserInfoState {
	id: string;
	name: string;
	branchName: string;
}

const Header: React.FC<IHeaderProps> = ({ visible }) => {
	const { globalDispatch } = useJellybookGlobal();
	const { isMaster, branchList, pccIndex } = useAuthStore();
	const history = useHistory();
	const [visibleHeader, setVisibleHeader] = useState<boolean>(visible);
	const [userInfoState, setUserInfoState] = useState<IUserInfoState>({
		id: '',
		name: '',
		branchName: '',
	});

	const [selectPccIndex, setSelectPccIndex] = useState(0);

	const handleLogout = () => {
		CareAlert.confirm({
			content: '로그아웃 하시겠습니까?',
			ok() {
				localStorage.removeItem('token');
				history.push('/login');
				globalDispatch({ type: 'LOGOUT' });
			},
		});
	};

	// 조리원 선택하기
	const onChange = (e) => {
		setSelectPccIndex(e);
	};

	// 선택 조리원으로 변경하기
	const handleSetPcc = React.useCallback(() => {
		setPccIndex(Number(selectPccIndex));
	}, [selectPccIndex]);

	// 테스트용 조리원 셀렉트
	// useEffect(() => {
	// 	if (branchList.length > 0) setPccIndex(47);
	// }, [branchList]);

	const getUserInfo = () => {
		if (getToken()) {
			const token = JSON.stringify(getToken());
			const { name, branchName, id }: IJWTPayload = jwt(token);
			setUserInfoState({
				id,
				name,
				branchName,
			});
		}
	};

	// 산후조리원 선택시 삭제된 산후조리원인지 체크
	const handleClickButton = useCallback(() => {
		if (branchList[Number(selectPccIndex)]?.delYn === 'Y') {
			CareAlert.info({ content: '영업 정지된 조리원 입니다.' });
		}
		handleSetPcc();
	}, [branchList, selectPccIndex, handleSetPcc, CareAlert]);

	useEffect(() => {
		setVisibleHeader(visible);
		getUserInfo();
		loadMasterOption();
	}, [visible]);

	return (
		<AppHeader visible={visibleHeader}>
			<HeaderLogo>
				<img src={logo} alt="jellybook" title="jellybook" />
			</HeaderLogo>
			<UserStatusWrap>
				{isMaster && branchList && branchList.length > 0 && (
					<>
						<Select
							showSearch
							optionFilterProp="children"
							// @ts-ignore
							filterOption={(input: string, option: React.ReactElement<any>) => {
								const { children } = option.props;

								if (option.type.isSelectOptGroup) {
									return children.includes((child) => child.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0);
								}
								return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
							}}
							value={selectPccIndex}
							onChange={(e) => onChange(e)}
							style={{ width: 200, borderRadius: 100 }}
						>
							{branchList.map((branch, index) => (
								<Select.Option value={index} key={index} style={branch.delYn === 'Y' && { color: '#ff0000' }}>
									{branch.delYn === 'Y' ? branch.branchName + '(영업종료)' : branch.branchName}
								</Select.Option>
							))}
						</Select>

						<PccBtn onClick={handleClickButton}>선택</PccBtn>

						<p style={{ marginLeft: 10 }}>
							현재 계정 권한: <strong style={{ color: '#ed7c89' }}>{branchList[pccIndex]?.branchName}</strong>
						</p>
						{/* <MasterSelect defaultValue={pccIndex} onChange={(e) => setPccIndex(Number(e.target.value))}>
							{branchList.map((branch, index) => (
								<option value={index} key={index}>
									{branch.branchName}
								</option>
							))}
						</MasterSelect> */}
					</>
				)}
				<UserNameWrap>
					<UserBranch>{userInfoState.branchName}</UserBranch>
					<UserName>{userInfoState.name !== '' ? userInfoState.name : userInfoState.id}님</UserName>
				</UserNameWrap>
				<LogoutBtn onClick={handleLogout} />
			</UserStatusWrap>
		</AppHeader>
	);
};

const AppHeader = styled.header`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 71px;
	border-bottom: 1px solid #dcdcdc;
	z-index: 10;
	${(props: IHeaderProps) => (props.visible ? 'display: flex;' : 'display: none;')}
`;

const HeaderLogo = styled.div`
	height: 100%;
	width: 256px;
	display: flex;
	align-items: center;
	padding-left: 22px;
	border-right: 1px solid #dcdcdc;
	background-color: #fff;
`;

const NoticeMsgDiv = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	padding-left: 22px;
	align-items: center;
`;

const UserStatusWrap = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: right;
	padding-left: 22px;
	align-items: center;
`;

const UserNameWrap = styled.div`
	display: flex;
	align-items: center;
	text-align: center;
	color: #151515;
	font-size: 13px;
	letter-spacing: -0.18px;
	transform: skew(1deg);
	min-width: 192px;
	border-right: 1px solid transparent;
	&:after {
		display: block;
		content: '';
		width: 1px;
		height: 30px;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		background-color: #f6f1fe;
	}
`;

const UserBranch = styled.span`
	display: block;
	margin-right: 5px;
	min-width: 108px;
`;

const UserName = styled.strong`
	font-weight: bold;
	margin-right: 20px;
`;

const LogoutBtn = styled.button`
	width: 57px;
	height: 100%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	&:before {
		display: block;
		content: '';
		width: 16px;
		height: 16px;
		background-image: url(${logoutIcon});
		background-position: center;
		background-repeat: no-repeat;
		background-size: 16px;
	}
`;

const PccBtn = styled.button`
	height: 30px;
	width: 100px;
	border-radius: 4px;
	background-color: #ed7c89;
	border: 1px solid #ed7c89;
	color: #fff;
	margin-left: 10px;
	font-weight: bold;
	cursor: pointer;
`;

const MasterSelect = styled.select`
	margin-right: 10px;
	height: 20px;
	font-size: 14px;
`;

export default Header;
