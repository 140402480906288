// REACT
import React from "react";
import { useHistory } from "react-router-dom";

// STYLE
import { CareButton } from "../../style/jellybook-component";
import styled from "styled-components";

// IMAGE
import errorImage403 from "../../assets/images/error-403.png";

const Forbidden: React.FC = () => {
  const history = useHistory();
  return (
    <ErrorContainer>
      <ErrorImg src={errorImage403} alt="방문한 페이지가 존재하지 않습니다." />
      <ErrorMessage>페이지에 접근권한이 없습니다.</ErrorMessage>
      <CareButton shape="round" bgType="jellybook" onClick={() => history.goBack()}>
        돌아가기
      </CareButton>
    </ErrorContainer>
  );
};

const ErrorContainer = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 205px;
`;
const ErrorImg = styled.img`
  margin-bottom: 63px;
`;

const ErrorTxt = styled.div`
  text-align: center;
  color: #bababa;
  font-size: 16px;
  font-weight: bold;
`;
const ErrorMessage = styled(ErrorTxt)`
  font-size: 14px;
  margin-bottom: 24px;
`;
export default Forbidden;
