import { useRef, useEffect } from 'react';

export default function useInterval(callback, delay) {
	const savedCallback = useRef(null);

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		const tick = () => {
			savedCallback.current();
		};
		if (delay !== null) {
			let timerId = setInterval(tick, delay);
			return () => clearInterval(timerId);
		}
	}, [delay]);
}
