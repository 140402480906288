import { $http, IApiResponse, TSuccess } from '../../services';
import { VIEW_API_BASE_URL } from '../../config';
import { IReviewListParams } from '../../pages/Review';
import axios from 'axios';
interface IGetReviewListResponse extends IApiResponse {
	totalCnt: number;
	period: IReviewItem[];
}

export interface IReviewItem {
	no: number;
	rvId: number;
	regDt: string;
	score: string;
	text: string;
	isReply: number;
	replyContents: string;
	isBlind: number;
	scoreClean: number;
	scoreFood: number;
	scoreService: number;
}

export interface IReplyReviewRequest {
	rvId: number;
	reply: string;
	replyRegId: string;
}

export interface IBlindRequest {
	rvId: number;
	blindReason: string;
	replyRegId: string;
}
export const getReviewList = (params: IReviewListParams) => {
	return new Promise((resolve: (response: IGetReviewListResponse) => void, reject: (e: Error) => void) => {
		axios({
			headers: {
				'book-token': '37fcf686-b9eb-4125-bd2f-cf83a643722b',
			},
			baseURL: VIEW_API_BASE_URL,
			url: '/review',
			method: 'GET',
			params,
		})
			.then((response) => resolve(response.data))
			.catch((e) => {
				const { response } = e;
				if (!response) {
					reject(e);
				} else {
					reject(response.data);
				}
			});
	});
};

export const replyReview = (data: IReplyReviewRequest) => {
	return new Promise((resolve: (response: any) => void, reject: (e: Error) => void) => {
		axios({
			headers: {
				'book-token': '37fcf686-b9eb-4125-bd2f-cf83a643722b',
			},
			baseURL: VIEW_API_BASE_URL,
			url: '/review/reply',
			method: 'PATCH',
			data,
		})
			.then((response) => resolve(response.data))
			.catch((e) => {
				const { response } = e;
				if (!response) {
					reject(e);
				} else {
					reject(response.data);
				}
			});
	});
};

export const requestBlind = (data: IBlindRequest) => {
	return new Promise((resolve: (response: any) => void, reject: (e: Error) => void) => {
		axios({
			headers: {
				'book-token': '37fcf686-b9eb-4125-bd2f-cf83a643722b',
			},
			baseURL: VIEW_API_BASE_URL,
			url: '/review/blind',
			method: 'PATCH',
			data,
		})
			.then((response) => resolve(response.data))
			.catch((e) => {
				const { response } = e;
				if (!response) {
					reject(e);
				} else {
					reject(response.data);
				}
			});
	});
};
