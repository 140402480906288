//REACT
import React from 'react';

//STYLE
import styled from 'styled-components';
import { Dimmed } from '../../../style';
import { Modal } from 'antd';

//IMAGE
import dialogClose from '../../../assets/images/ic-popover-close.svg';

const { confirm, info } = Modal;

export interface IJellyDialog {
	children?: any;
	visible: boolean;
	title: string;
	buttons?: any;
	dialogWidth?: string;
	onPressClose(): void;
	[propName: string]: any;
}

interface IDialogProps {
	visible: boolean;
	dialogWidth: string;
	[propName: string]: any;
}

export interface IDialogMethodInfo {
	title: string;
	content: string;
}
export interface IDialogMethodConfirm extends IDialogMethodInfo {
	ok: () => void;
	cancel?: () => void;
}

export const JellyDialog = ({ children, title, visible, onPressClose, buttons, dialogWidth, isTwoColumn = false, ...props }: IJellyDialog) => {
	return (
		<Dimmed visible={visible}>
			<Dialog dialogWidth={dialogWidth} visible={visible} {...props}>
				<DialogHeader>
					<DialogTitle>{title}</DialogTitle>
					<DialogCloseBtn onClick={onPressClose} />
				</DialogHeader>
				<DialogContents>{children}</DialogContents>
				{buttons && <DialogButtonWrap>{buttons}</DialogButtonWrap>}
			</Dialog>
		</Dimmed>
	);
};

export const JellyCamDialog = ({ children, title, visible, onPressClose, buttons, dialogWidth, ...props }: IJellyDialog) => {
	return (
		<Dimmed visible={visible} onClick={onPressClose}>
			<Dialog dialogWidth={dialogWidth} visible={visible} {...props}>
				<DialogHeader>
					<DialogTitle>{title}</DialogTitle>
					<DialogCloseBtn onClick={onPressClose} />
				</DialogHeader>
				<DialogContents>{children}</DialogContents>
				{buttons && <DialogButtonWrap>{buttons}</DialogButtonWrap>}
			</Dialog>
		</Dimmed>
	);
};

JellyDialog.info = ({ title, content }: IDialogMethodInfo) => {
	info({
		title,
		content,
		okText: '확인',
		className: 'jellybook-dialog--info',
	});
};
JellyDialog.confirm = ({ title, content, ok, cancel }: IDialogMethodConfirm) => {
	confirm({
		title,
		content,
		onOk() {
			if (ok) {
				ok();
			}
		},
		onCancel() {
			if (cancel) {
				cancel();
			}
		},
		okText: '확인',
		cancelText: '취소',
		className: 'jellybook-dialog--confirm',
	});
};

JellyCamDialog.info = ({ title, content }: IDialogMethodInfo) => {
	info({
		title,
		content,
		okText: '확인',
		className: 'jellybook-dialog--info',
	});
};
JellyCamDialog.confirm = ({ title, content, ok, cancel }: IDialogMethodConfirm) => {
	confirm({
		title,
		content,
		onOk() {
			if (ok) {
				ok();
			}
		},
		onCancel() {
			if (cancel) {
				cancel();
			}
		},
		okText: '확인',
		cancelText: '취소',
		className: 'jellybook-dialog--confirm',
	});
};

const Dialog = styled.div`
	transform: skew(0.05deg);
	background-color: #fff;
	border-radius: 8px;
	position: relative;
	overflow: hidden;
	box-shadow: 0 0 25px 0 rgba(28, 21, 56, 0.24);
	width: ${(props: IDialogProps) => (props.dialogWidth ? `${props.dialogWidth}` : '330px;')};
	visibility: ${(props: IDialogProps) => (props.visible ? 'visible' : 'hidden')};
`;

const DialogHeader = styled.header`
	height: 52px;
	line-height: 52px;
	padding-left: 20px;
	padding-right: 24px;
	background-color: #ed7c89;
`;

const DialogTitle = styled.h3`
	font-size: 16px;
	color: #fff;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const DialogCloseBtn = styled.button`
	width: 24px;
	height: 24px;
	outline: 0;
	background-image: url(${dialogClose});
	background-size: 12px;
	background-position: 0 6px;
	background-repeat: no-repeat;
	position: absolute;
	right: 0;
	top: 6px;
	cursor: pointer;
`;

const DialogContents = styled.div`
	max-height: 510px;
	padding: 20px;
	overflow-y: auto;
`;

const DialogButtonWrap = styled.div`
	padding: 0 20px 20px 20px;
	display: flex;
	justify-content: flex-end;
`;
