//REACT
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

//STYLE
import styled from 'styled-components';

//COMPONENT
import { Header, Nav } from '../../index';

//CONTEXT
import { useJellybookGlobal } from '../../../contexts';

interface IFrameContiner {
	visible: boolean;
}
const Frame: React.FC = ({ children }) => {
	const history = useLocation();
	const {
		globalState: { hasFrame },
	} = useJellybookGlobal();
	const { pathname } = history;

	const [visibleFrame, setVisibleFrame] = useState<boolean>(false);
	useEffect(() => {
		const toggleFrame = hasFrame.some((item: string) => item === pathname);
		if (toggleFrame) {
			setVisibleFrame(true);
		} else {
			setVisibleFrame(false);
		}
	}, [pathname]);
	return (
		<FrameContainer visible={visibleFrame}>
			<Header visible={visibleFrame} />
			<Nav visible={visibleFrame} />
			<RouteWrap visible={visibleFrame}>{children}</RouteWrap>
		</FrameContainer>
	);
};

const FrameContainer = styled.div`
	${(props: IFrameContiner) => (props.visible ? `padding-top:71px` : `padding-top:0;`)}
	${(props: IFrameContiner) => (props.visible ? `padding-left: 220px;` : `padding-left: 0;`)}
  min-width: 1440px;
	height: 100%;
	min-height: 100%;
	position: relative;
`;

const RouteWrap = styled.div`
	${(props: IFrameContiner) => (props.visible ? `padding: 40px 50px; height:auto;` : `padding: 0;height:100%;`)}
	background-color: #f4f4f4;
	min-height: 100%;
	position: relative;
`;

export default Frame;
