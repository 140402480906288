//REACT
import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

//WRAPPERS
import Login from '../pages/Login';
import Customer from '../pages/Customer';
import Cam from '../pages/Cam';
import Config from '../pages/Config';
import NotFound from '../pages/NotFound';
import Forbidden from '../pages/Forbidden';
import ChangePassword from '../pages/ChangePassword';
import Review from '../pages/Review';
import Notice from '../pages/Notice';

const Routes = ({ isLogged }) => {
	const [loginState, setLoginState] = useState(isLogged);
	useEffect(() => {
		setLoginState(isLogged);
	}, [isLogged]);
	return (
		<Switch>
			<Redirect exact from="**/index.html" to="/" />
			<Route exact path={['/', '/customer']} render={(props) => (loginState ? <Customer {...props} /> : <Redirect to="/login" />)} />
			<Route path="/changePassword" component={ChangePassword} />
			<Route path="/login" component={Login} />
			<Route path="/notice" render={(props) => (loginState ? <Notice {...props} /> : <Redirect to="/login" />)} />
			<Route path="/cam" render={(props) => (loginState ? <Cam {...props} /> : <Redirect to="/login" />)} />
			<Route path="/config" render={(props) => (loginState ? <Config {...props} /> : <Redirect to="/login" />)} />
			<Route path="/review" render={(props) => (loginState ? <Review {...props} /> : <Redirect to="/login" />)} />
			<Route path="/403" component={Forbidden} />
			<Route path={['/404', '*']} component={NotFound} />
		</Switch>
	);
};

export default Routes;
