//REACT
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';

//STYLE
import { Modal } from 'antd';
import styled from 'styled-components';
import { Dimmed } from './index';

interface IAlertMethodInfo {
	content: any;
	ok?: () => void;
}
interface IAlertMethodConfirm extends IAlertMethodInfo {
	ok: () => void;
	cancel?: () => void;
}
interface ICareAlert {
	children: any;
	visible: boolean;
	callback(): void;
	[propName: string]: any;
}

const { info, confirm } = Modal;

export const CareAlert = ({ children, visible, callback, width = null, ...props }: ICareAlert) => {
	const [alertVisible, setAlertnVisible] = useState<boolean>(false);
	//TODO: type
	const btnRef = useRef<HTMLButtonElement | any>(null);

	useEffect(() => {
		setAlertnVisible(visible);
	}, [visible]);

	useLayoutEffect(() => {
		if (visible && btnRef) {
			btnRef.current.focus();
		}
	});

	return (
		<Dimmed visible={alertVisible}>
			<Alert {...props} style={{ width: width === null ? 270 : 'auto' }}>
				<AlertContent>{children}</AlertContent>
				<AlertButton ref={btnRef} onClick={callback}>
					확인
				</AlertButton>
			</Alert>
		</Dimmed>
	);
};

CareAlert.info = ({ content, ok }: IAlertMethodInfo) => {
	info({
		content,
		className: 'jellybook-alert jellybook-alert--info',
		okText: '확인',
		onOk() {
			if (ok) {
				ok();
			}
		},
	});
};

CareAlert.confirm = ({ content, ok, cancel }: IAlertMethodConfirm) => {
	confirm({
		content,
		onOk() {
			if (ok) {
				ok();
			}
		},
		onCancel() {
			if (cancel) {
				cancel();
			}
		},
		okText: '확인',
		cancelText: '취소',
		className: 'jellybook-alert jellybook-alert--confirm',
	});
};
const Alert = styled.div`
	border-radius: 8px;
	box-shadow: 0 0 25px 0 rgba(28, 21, 56, 0.24);
	padding-bottom: 40px;
	background-color: #fff;
	position: relative;
	text-align: center;
	color: #151515;
`;

const AlertContent = styled.div`
	font-size: 14px;
	font-weight: 400;
	padding: 20px 34px;
	transform: skew(0.05deg);
`;
const AlertButton = styled.button`
	position: absolute;
	left: 0;
	bottom: 0;
	height: 40px;
	width: 100%;
	border-top: 1px solid #eee;
	font-weight: bold;
	transform: skew(0.05deg);
	cursor: pointer;
`;
export default CareAlert;
