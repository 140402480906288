//REACT
import React, { useState, useEffect } from "react";

//STYLE
import styled, { keyframes, css } from "styled-components";

//UTIL
import moment from "moment";

interface ICareLoading {
  visible: boolean;
  fill?: boolean | string;
  delay?: number; // Millisecond
}
interface ICareLoadingDot {
  idx: number;
}

const JellyLoading: React.FC<ICareLoading> = ({ visible, fill = true, delay = 1200 }) => {
  const [innerState, setInnerState] = useState<boolean>(visible);
  useEffect(() => {
    const initTimeLog = parseInt(moment().format("ss"));
    let timeout: any;
    const toggleVisible = () => {
      if (parseInt(moment().format("ss")) - initTimeLog > 1) {
        setInnerState(visible);
      } else {
        if (visible) {
          setInnerState(visible);
        } else {
          timeout = setTimeout(() => {
            setInnerState(visible);
          }, delay);
        }
      }
    };
    toggleVisible();
    return () => clearTimeout(timeout);
  }, [visible]);
  return (
    <LoadingWrap visible={innerState} fill={fill.toString()}>
      <LoadingContainer>
        <LoadingDot idx={1} />
        <LoadingDot idx={2} />
        <LoadingDot idx={3} />
      </LoadingContainer>

      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7" />
          </filter>
        </defs>
      </svg>
    </LoadingWrap>
  );
};

const dot3 = keyframes`
  20% {transform: scale(1)}
  45% {transform: translateY(-18px) scale(.45)}
  60% {transform: translateY(-90px) scale(.45)}
  80% {transform: translateY(-90px) scale(.45)}
  100% {transform: translateY(0px) scale(1)}
`;
const dot2 = keyframes`
  20% {transform: scale(1)}
  45% {transform: translate(-16px, 12px) scale(.45)}
  60% {transform: translate(-80px, 60px) scale(.45)}
  80% {transform: translate(-80px, 60px) scale(.45)}
  100% {transform: translateY(0px) scale(1)}
`;
const dot1 = keyframes`
  20% {transform: scale(1)}
  45% {transform: translate(16px, 12px) scale(.45)}
  60% {transform: translate(80px, 60px) scale(.45)}
  80% {transform: translate(80px, 60px) scale(.45)}
  100% {transform: translateY(0px) scale(1)}
`;
const rotateMove = keyframes`
  55% {transform: translate(-50%, -50%) rotate(0deg)}
  80% {transform: translate(-50%, -50%) rotate(360deg)}
  100% {transform: translate(-50%, -50%) rotate(360deg)}
`;
const zIndex = keyframes`
  0%, 100% {z-index: 3}
  33.3% {z-index: 2}
  66.6% {z-index: 1}
`;

const LoadingWrap = styled.div`
  width: 100%;
  height: 100%;
  position: ${({ fill }: ICareLoading) => (fill === "true" ? "fixed" : "absolute")};
  display: ${({ visible }: ICareLoading) => (visible ? "block" : "none")};
  background-color: rgba(255, 255, 255, 0.6);
  top: 0;
  left: 0;
  z-index: 30;
`;

const LoadingContainer = styled.div`
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  filter: url("#goo");
  animation: ${rotateMove} 2s ease-in-out infinite;
  z-index: 40;
`;

const LoadingDot = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation-duration: 1s !important;
  ${({ idx }: ICareLoadingDot) => {
    if (idx === 1) {
      return css`
        background-color: #e55863;
        animation: ${dot1} 2s ease infinite, ${zIndex} 6s -2s ease infinite;
      `;
    } else if (idx === 2) {
      return css`
        background-color: #f894bc;
        animation: ${dot2} 2s ease infinite, ${zIndex} 6s -4s ease infinite;
      `;
    } else {
      return css`
        background-color: #51c2f7;
        animation: ${dot3} 2s ease infinite, ${zIndex} 6s ease infinite;
      `;
    }
  }}
`;

export default JellyLoading;
