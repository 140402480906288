import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { io } from "socket.io-client";
import { CamDialog } from "../../style/jellybook-component/Dialog";

const SOCKET_URL = "https://ffmpeg.jellyapi.com";
const DialogCam = ({ visible, title, camUrl, onPressClose }) => {
  const canvasRef = React.useRef();
  const [socket, setSocket] = useState(null);

  const onLoad = async () => {
    try {
      const response = await fetch(SOCKET_URL + "/camera/stream?url=" + camUrl).then(res => res.json());
      const _socket = io(SOCKET_URL + "/" + response.url, {
        transports: ["websocket"]
      });
      setSocket(_socket);
    } catch (e) {
      console.log(e);
    }
  };

  const renderCamera = () => {
    if (camUrl.startsWith("rtsp")) {
      return <canvas ref={canvasRef} width="640" height="480" style={{ width: 640, height: 480 }} />;
    }

    return <iframe src={camUrl} scrolling="no" />;
  };

  useEffect(() => {
    if (socket) {
      socket.on(`stream.buffer`, function(data) {
        var ctx = canvasRef.current.getContext("2d");

        var bytes = new Uint8Array(data);
        var blob = new Blob([bytes], { type: "application/octet-binary" });
        var url = URL.createObjectURL(blob);
        var img = new Image();

        img.onload = function() {
          ctx.drawImage(img, 0, 0, 640, 480);
        };

        img.onerror = function(e) {
          console.log(e);
        };
        img.src = url;
      });
    }
    return () => {
      if (socket) {
        socket.disconnect();
        setSocket(null);
      }
    };
  }, [socket]);
  useEffect(() => {
    if (camUrl.startsWith("rtsp")) {
      onLoad();
    }
  }, []);
  if (!visible) {
    return null;
  }

  return (
    <CamDialog style={{ width: 683 }} title={title} visible={visible} onPressClose={onPressClose}>
      <CamImageWrap>{renderCamera()}</CamImageWrap>
    </CamDialog>
  );
};

const CamImageWrap = styled.div`
  width: 100%;
  height: 470px;
  overflow: hidden;
  background: url("/static/media/ic-cam-loading.98e4ba4a.svg") no-repeat center;
  z-index: 1;

  iframe {
    width: 100%;
    height: 100%;
  }
`;

export default DialogCam;
