import React from "react";
import ReactDOM from "react-dom";
import Root from "./Root";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "promise-polyfill/src/polyfill";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

ReactDOM.render(
  <BrowserRouter>
    <Root />
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.register();
