//REACT
import React, { useReducer } from "react";

//STYLE
import "antd/dist/antd.css";
import "./style/style.scss";
import { createGlobalStyle } from "styled-components";

//CONTEXT
import { GlobalContext } from "./contexts";

//REDUCER
import { globalReducer, globalStateInit } from "./reducers";

//COMPONENT
import { Frame } from "./components";
import Routes from "./routes";

const Root: React.FC = () => {
  const [state, dispatch] = useReducer(globalReducer, globalStateInit);
  return (
    <GlobalContext.Provider value={{ globalState: state, globalDispatch: dispatch }}>
      <CssReset />
      <Frame>
        <Routes isLogged={state.isLogged} />
      </Frame>
    </GlobalContext.Provider>
  );
};

const CssReset = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    font-style: normal
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
    font-size:14px;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a:link,a:visited{
    text-decoration: none;
    color:inherit;
  }
  html,body{
    position: relative;
    height:100%;
  }
  input,button{
    background-color:transparent;
    border:none;
    outline: none;
  }
  input:focus {
    outline: none;
  }
  #root{
    min-height:100%;
    height:100%;
    overflow:auto;
    position:relative;
  }
`;

export default Root;
