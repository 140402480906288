// @ts-nocheck

//REACT
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import useAuthStore from '../../../store/auth/authStore';

//STYLE
import styled from 'styled-components';

//IMAGE
import camOff from '../../../assets/images/ic-category-cam-off.svg';
import camOn from '../../../assets/images/ic-category-cam-on.svg';
import customerOff from '../../../assets/images/ic-category-list-off.svg';
import customerOn from '../../../assets/images/ic-category-list-on.svg';
import configOff from '../../../assets/images/ic-category-setup-off.svg';
import configOn from '../../../assets/images/ic-category-setup-on.svg';
import reviewOn from '../../../assets/images/ic-category-review-on.svg';
import reviewOff from '../../../assets/images/ic-category-review-off.svg';
import noticeOn from '../../../assets/images/ic-category-notice-on.svg';
import noticeOff from '../../../assets/images/ic-category-notice-off.svg';
import connectGuide from '../../../assets/images/ic-download.png';
import btnForIMCare from '../../../assets/images/btn_imcare.png';
import btnForGuide from '../../../assets/images/btn_guide.png';
import btnForGuide2 from '../../../assets/images/btn_guide2.png';
import btnForAs from '../../../assets/images/btn_as.png';
import btnOrderOurHome from '../../../assets/images/ourHomeOrder.png';

interface INavProps {
	visible: boolean;
}

const Nav: React.FC<INavProps> = ({ visible }) => {
	const [visibleNav, setVisibleNav] = useState<boolean>(visible);

	const { isOurHome } = useAuthStore();

	useEffect(() => {
		setVisibleNav(visible);
	}, [visible]);
	return (
		<AppNav visible={visibleNav}>
			<MenuList>
				<MenuItem>
					<NavLink to="/notice" className="notice">
						공지사항
					</NavLink>
				</MenuItem>
				<MenuItem>
					<NavLink exact to="/" className="customer">
						고객목록
					</NavLink>
				</MenuItem>
				<MenuItem>
					<NavLink to="/cam" className="cam">
						캠목록
					</NavLink>
				</MenuItem>
				<MenuItem>
					<NavLink to="/review" className="review">
						리뷰관리
					</NavLink>
				</MenuItem>
				<MenuItem>
					<NavLink to="/config" className="config">
						환경설정
					</NavLink>
				</MenuItem>
			</MenuList>
			<ConnectGuideButton
				href="https://s3.ap-northeast-2.amazonaws.com/live.com.jellybook.file/guide/%EC%A0%A4%EB%A6%AC%EC%BA%A0%EC%9C%A0%EB%A3%8C%ED%99%94%EC%95%88%EB%82%B4_231101.pdf"
				// href="/document.pdf"
				download
				target="_blank"
				rel="noopener noreferrer"
			>
				{/* <ConnectGuideButtonLabel>젤리뷰 유료화 안내</ConnectGuideButtonLabel> */}
				<img src={btnForGuide2} style={{ width: '100%', display: 'block' }} />
			</ConnectGuideButton>
			<ConnectGuideButton
				// href="https://s3.ap-northeast-2.amazonaws.com/live.com.jellybook.file/guide/(230724)%EC%A0%A4%EB%A6%AC%EC%BA%A0+%EC%82%B0%ED%9B%84%EC%A1%B0%EB%A6%AC%EC%9B%90_%EC%9C%A0%EB%A3%8C%ED%99%94_%EA%B3%B5%EC%A7%80.pdf"
				href="https://s3.ap-northeast-2.amazonaws.com/live.com.jellybook.file/guide/(231110%EC%A0%A4%EB%A6%AC%EC%BA%A0+%EC%82%B0%ED%9B%84%EC%A1%B0%EB%A6%AC%EC%9B%90_%EC%9C%A0%EB%A3%8C%ED%99%94_%EA%B3%B5%EC%A7%80_+v2.pdf"
				// href="/document.pdf"
				download
				target="_blank"
				rel="noopener noreferrer"
			>
				{/* <ConnectGuideButtonLabel>젤리캠 이용방법</ConnectGuideButtonLabel> */}
				<img src={btnForGuide} style={{ width: '100%', display: 'block' }} />
			</ConnectGuideButton>
			<ExternalConnectBtn href="https://forms.gle/R6F4Huk6kFNivMDq6" target="_blank">
				<ExternalConnectBtnImg src={btnForAs} style={{ width: '100%', display: 'block' }} />
			</ExternalConnectBtn>
			<ExternalConnectBtn href="https://imomcare.net/" target="_blank">
				<ExternalConnectBtnImg src={btnForIMCare} />
			</ExternalConnectBtn>
			{isOurHome && (
				<ExternalConnectBtn href="https://tos.ourhome.co.kr/NTOS/login.do" target="_blank">
					<ExternalConnectBtnImg src={btnOrderOurHome} />
				</ExternalConnectBtn>
			)}
			<CSBox>
				<p className="title">젤리캠 고객센터</p>
				<p className="text">02-6205-1315</p>
				<p className="text">-운영시간-</p>
				<p className="text">평일 10:00 ~ 17:00</p>
				<p className="text">점심시간 11:30 ~ 12:30</p>
				<p className="text">주말 및 공휴일 휴무</p>
			</CSBox>
		</AppNav>
	);
};

const AppNav = styled.nav`
	position: absolute;
	height: calc(100% - 71px);
	overflow-y: auto;
	width: 220px;
	left: 0;
	top: 71px;
	border-right: 1px solid #dcdcdc;
	${(props: INavProps) => (props.visible ? 'display: block;' : 'display: none;')}
`;

const MenuList = styled.ul`
	font-weight: bold;
`;
const MenuItem = styled.li`
	height: 50px;
	a {
		padding: 0 24px;
		display: flex;
		height: 100%;
		align-items: center;
		color: #bababa;
		transform: skew(1deg);
		&.notice {
			&:before {
				background-image: url(${noticeOff});
			}
		}
		&.cam {
			&:before {
				background-image: url(${camOff});
			}
		}
		&.customer {
			&:before {
				background-image: url(${customerOff});
			}
		}
		&.config {
			&:before {
				background-image: url(${configOff});
			}
		}
		&.review {
			&:before {
				background-image: url(${reviewOff});
			}
		}

		&:before {
			width: 16px;
			height: 16px;
			content: '';
			display: block;
			margin-right: 17px;
			background-size: auto 100%;
			background-repeat: no-repeat;
			background-position: center;
		}

		&.active {
			background-color: #fff3f1;
			color: #e55863;
			position: relative;
			&.notice {
				&:before {
					background-image: url(${noticeOn});
				}
			}
			&.cam {
				&:before {
					background-image: url(${camOn});
				}
			}
			&.customer {
				&:before {
					background-image: url(${customerOn});
				}
			}
			&.config {
				&:before {
					background-image: url(${configOn});
				}
			}
			&.review {
				&:before {
					background-image: url(${reviewOn});
				}
			}
			&:after {
				display: block;
				width: 3px;
				height: 100%;
				background-color: #e55863;
				left: 0;
				top: 0;
				position: absolute;
				content: '';
			}
		}
	}
`;

const ConnectGuideButton = styled.a`
	/* height: 37px; */
	/* border: solid 1px #ed7c89; */
	width: 186px;
	height: auto;
	font-weight: bold;
	font-size: 14px;
	display: flex;
	align-items: center;
	margin: 16px auto;
	/* width: 176px; */
	justify-content: center;
`;

const ConnectGuideButtonLabel = styled.span`
	color: #ed7c89;
	margin-right: 5px;
`;

const ExternalConnectBtn = styled.a`
	height: auto;
	margin: 16px auto;
	/* width: 176px; */
	width: 186px;
	position: relative;
	display: block;
`;

const ExternalConnectBtnImg = styled.img`
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
`;

const CSBox = styled.div`
	margin: 16px auto;
	padding: 16px;
	display: flex;
	flex-direction: column;
	background-color: #ed7c89;
	border-radius: 8px;
	width: 186px;

	p {
		text-align: center;
		color: #fff;

		&.title {
			font-size: 15px;
			font-weight: bold;
			margin-bottom: 8px;
			padding-bottom: 8px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);
			white-space: nowrap;
		}

		&.text {
			margin: 4px 0;
			font-size: 13px;
			transform: skew(0.05deg);
			white-space: nowrap;
		}
	}
`;
export default Nav;
