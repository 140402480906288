// REACT
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// COMPONENT
import { JellyDialog, JellyButton, JellyToast } from "../../components";

// STYLE
import styled from "styled-components";
import { JellyInput, JellyVerticalFormItem, JellyFormLabel, JellyFormErrorMessage, JellyInfoWrap } from "../../style";

// API
import { updatePassword } from "../../api";

// UTIL
import { httpError } from "../../services";

interface IDialogProps {
  visible: boolean;
  onPressClose: () => void;
}

interface IPasswordData {
  current: string;
  new: string;
  confirm: string;
}

interface IRequiredStatus {
  current: boolean;
  new: boolean;
  confirm: boolean;
}

const DialogChangePassword: React.FC<IDialogProps> = ({ visible, onPressClose }) => {
  const history = useHistory();
  const [requestToggle, setRequestToggle] = useState<boolean>(false);
  const [requiredStatus, setRequiredStatus] = useState<IRequiredStatus>({ current: true, new: true, confirm: true });
  const [errorMsg, setErrorMsg] = useState<IPasswordData>({ current: "", new: "", confirm: "" });
  const [password, setPassword] = useState<IPasswordData>({
    current: "",
    new: "",
    confirm: ""
  });

  const MESSAGE = {
    EMPTY: {
      CURRENT: "현재 비밀번호를 입력해주세요.",
      NEW: "새 비밀번호를 입력해주세요."
    },
    INCORRECT: {
      CURRENT: "현재 비밀번호가 일치하지 않습니다.",
      REGEX: "8~16자 영문, 숫자를 사용해주세요.",
      CONFIRM: "새 비밀번호가 일치하지 않습니다."
    }
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRequiredStatus({ current: true, new: true, confirm: true }); // INIT STATE
    setPassword({
      ...password,
      [e.target.name]: e.target.value
    });
  };

  const handleClickSubmit = async () => {
    if (requestToggle) {
      return;
    }
    const passwordRegex = /^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z]).*$/g;
    setRequiredStatus({ current: true, new: true, confirm: true }); // INIT STATE

    if (password.current.length <= 0) {
      setErrorMsg({ ...errorMsg, current: MESSAGE.EMPTY.CURRENT });
      setRequiredStatus({
        current: false,
        new: true,
        confirm: true
      });
    } else if (password.new.length <= 0) {
      setErrorMsg({ ...errorMsg, new: MESSAGE.EMPTY.NEW });
      setRequiredStatus({
        current: true,
        new: false,
        confirm: true
      });
    } else if (password.confirm.length <= 0) {
      setErrorMsg({ ...errorMsg, confirm: MESSAGE.EMPTY.NEW });
      setRequiredStatus({
        current: true,
        new: true,
        confirm: false
      });
    } else if (!password.new.match(passwordRegex)) {
      setErrorMsg({ ...errorMsg, new: MESSAGE.INCORRECT.REGEX });
      setRequiredStatus({
        current: true,
        new: false,
        confirm: true
      });
    } else if (password.new !== password.confirm) {
      setErrorMsg({ ...errorMsg, confirm: MESSAGE.INCORRECT.CONFIRM });
      setRequiredStatus({
        current: false,
        new: true,
        confirm: true
      });
    } else {
      setRequestToggle(true);
      try {
        const { success } = await updatePassword({ bfPasswd: password.current, passwd: password.new });
        if (success === 1) {
          JellyToast("비밀번호 변경이 완료되었습니다.");
          onPressClose();
        }
      } catch ({ code, errorParam }) {
        if (errorParam === "mismatch") {
          setErrorMsg({ ...errorMsg, current: MESSAGE.INCORRECT.CURRENT });
          setRequiredStatus({
            current: false,
            new: true,
            confirm: true
          });
        } else {
          httpError(history, code);
        }
      } finally {
        setRequestToggle(false);
      }
    }
  };

  const resetDialog = () => {
    setPassword({
      current: "",
      new: "",
      confirm: ""
    });
    setRequiredStatus({
      current: true,
      new: true,
      confirm: true
    });
    setErrorMsg({
      current: "",
      new: "",
      confirm: ""
    });
  };

  useEffect(() => {
    if (!visible) {
      resetDialog();
    }
    return () => {};
  }, [visible]);

  return (
    <JellyDialog
      title="비밀번호 변경하기"
      visible={visible}
      onPressClose={onPressClose}
      buttons={[
        <JellyButton key={1} bgType="jellybook" onClick={handleClickSubmit}>
          저장
        </JellyButton>
      ]}
    >
      <PasswordForm>
        <JellyVerticalFormItem>
          <JellyFormLabel vertical>현재 비밀번호</JellyFormLabel>
          <JellyInfoWrap>
            <JellyInput
              placeholder="현재 비밀번호를 입력해주세요."
              value={password.current}
              name="current"
              onChange={handleChangePassword}
              type="password"
            />
            {!requiredStatus.current && <JellyFormErrorMessage>{errorMsg.current}</JellyFormErrorMessage>}
          </JellyInfoWrap>
        </JellyVerticalFormItem>
        <JellyVerticalFormItem>
          <JellyFormLabel vertical>변경 비밀번호</JellyFormLabel>
          <JellyInfoWrap>
            <JellyInput
              placeholder="8~16 (영문, 숫자 조합)"
              value={password.new}
              name="new"
              onChange={handleChangePassword}
              type="password"
            />
            {!requiredStatus.new && <JellyFormErrorMessage>{errorMsg.new}</JellyFormErrorMessage>}
          </JellyInfoWrap>
        </JellyVerticalFormItem>
        <JellyVerticalFormItem>
          <JellyFormLabel vertical>비밀번호 확인</JellyFormLabel>
          <JellyInfoWrap>
            <JellyInput
              placeholder="8~16 (영문, 숫자 조합)"
              value={password.confirm}
              name="confirm"
              onChange={handleChangePassword}
              type="password"
            />
            {!requiredStatus.confirm && <JellyFormErrorMessage>{errorMsg.confirm}</JellyFormErrorMessage>}
          </JellyInfoWrap>
        </JellyVerticalFormItem>
      </PasswordForm>
    </JellyDialog>
  );
};

const PasswordForm = styled.div``;

export default DialogChangePassword;
