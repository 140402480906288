import styled from "styled-components";

export const ReviewTitle = styled.h2`
  color: #151515;
  font-size: 22px;
  font-weight: 900;
  display: flex;
  align-items: center;
  transform: skew(0.05deg);
  margin-right: 25px;
`;

export const ReviewSortWrap = styled.div`
  padding: 25px;
  padding-right: 0;
`;
export const ReviewTableWrap = styled.div`
  padding: 0 25px 61px 25px;
  position: relative;
`;

export const ReviewTextarea = styled.textarea`
  height: 300px;
  width: 100%;
  resize: none;
  padding: 15px;
  border-radius: 3px;
  border: 1px solid #e6e6e6;
  font-size: 14px;
  color: #151515;
  transform: skew(0.05deg);
  font-weight: 600;
  line-height: 1.54;
`;

export const ReviewBlindTextWrap = styled.div``;
export const ReviewBlindText = styled.p`
  padding-top: 15px;
  color: rgb(139, 139, 139);
  font-size: 13px;
  line-height: 1.54;
  transform: skew(0.05deg);
  margin-bottom: 20px;
`;

export const ReviewRowContent = styled.button`
  color: #e55863;
  cursor: pointer;
`;

export const ReviewScoreWrap = styled.div`
  display: flex;
`;

export const ReviewScoreItem = styled.div`
  display: flex;
  margin-right: 10px;
`;

export const ReviewScoreLabel = styled.span`
  font-weight: bold;
  display: inline-block;
  margin-right: 5px;
`;
export const ReviewScoreRating = styled.span`
  font-weight: bold;
  color: #e55863;
`;

export const NoDataWrap = styled.div`
  height: 530px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
`;

export const NoDataImg = styled.img`
  margin-bottom: 24px;
`;
export const NoDataTxt = styled.p`
  color: #bababa;
  font-weight: 400;
  transform: skew(0.05deg);
`;
