//REACT
import React, { useState } from "react";

//COMPONENT
import { JellyDialog, JellyButton } from "../../components";

//STYLE
import {
  ReviewTextarea,
  ReviewBlindText,
  ReviewScoreWrap,
  ReviewScoreItem,
  ReviewScoreLabel,
  ReviewScoreRating
} from "./style";

interface IDialogReviewReply {
  visible: boolean;
  [key: string]: any;
}

const DialogReviewReply: React.FC<IDialogReviewReply> = ({ visible, data, onPressClose, onReply }) => {
  const [reply, setReply] = useState<String>("");

  const handleSubmit = () => {
    if (reply.trim().length >= 1) {
      onReply(data.rvId, reply);
    } else {
      JellyDialog.info({
        title: "답글작성",
        content: "답글을 작성해주세요."
      });
    }
  };
  return (
    <JellyDialog
      title="답글달기"
      visible={visible}
      dialogWidth="1000px"
      onPressClose={onPressClose}
      buttons={[
        <JellyButton key={1} bgType="white" onClick={onPressClose}>
          취소
        </JellyButton>,
        <JellyButton key={2} bgType="jellybook" onClick={handleSubmit}>
          저장완료
        </JellyButton>
      ]}
    >
      {data && (
        <>
          <ReviewScoreWrap>
            <ReviewScoreItem>
              <ReviewScoreLabel>음식</ReviewScoreLabel>
              <span role="img">⭐️</span>
              <ReviewScoreRating>{data.scoreFood}</ReviewScoreRating>
            </ReviewScoreItem>
            <ReviewScoreItem>
              <ReviewScoreLabel>시설</ReviewScoreLabel>
              <span role="img">⭐️</span>
              <ReviewScoreRating>{data.scoreClean}</ReviewScoreRating>
            </ReviewScoreItem>
            <ReviewScoreItem>
              <ReviewScoreLabel>서비스</ReviewScoreLabel>
              <span role="img">⭐️</span>
              <ReviewScoreRating>{data.scoreService}</ReviewScoreRating>
            </ReviewScoreItem>
          </ReviewScoreWrap>
          <ReviewBlindText>{data.text}</ReviewBlindText>
          <ReviewTextarea
            placeholder="답변을 남겨주세요."
            defaultValue={data.replyContents}
            onChange={e => setReply(e.target.value)}
          ></ReviewTextarea>
        </>
      )}
    </JellyDialog>
  );
};

export default DialogReviewReply;
