//UTIL
import { isLogin } from '../utils';

export interface IGlobalState {
	searchKeyword: string;
	isLogged: boolean;
	hasFrame: string[];
}

export type TGlobalActions =
	| {
			type: 'SEARCH';
			keyword: string;
	  }
	| {
			type: 'LOGIN';
	  }
	| {
			type: 'LOGOUT';
	  }
	| {
			type: 'RESET';
	  };
export const globalStateInit = {
	searchKeyword: '',
	isLogged: isLogin(),
	hasFrame: ['/', '/customer', '/notice', '/cam', '/config', '/ui', '/changePassword', '/review'],
};

export function globalReducer(state: IGlobalState = globalStateInit, action: TGlobalActions): IGlobalState {
	switch (action.type) {
		case 'SEARCH':
			return { ...state, searchKeyword: action.keyword };
		case 'RESET':
			return { ...globalStateInit };
		case 'LOGIN':
			return {
				...state,
				isLogged: true,
			};
		case 'LOGOUT':
			return { ...globalStateInit, isLogged: false };
		default:
			return state;
	}
}
