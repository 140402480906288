//UTIL
import { $http, IApiResponse, TSuccess } from '../../services';

export interface ICamListData {
	camImg: string;
	camUrl: string;
	cameraName: string;
	cameraUid: string;
	momName: string;
	momUid: string;
	famWatchingTimeList: Array<ICamFamliyListData>;
}

export interface IPartyInfo {
	name: string;
	phone: string;
	relation: string;
}

export interface ICamInfoData {
	babyName: string;
	babyUid: string;
	cameraName: string;
	cameraUid: string;
	momBirh: string;
	momName: string;
	momPhone: string;
	momUid: string;
	partyInfo: Array<IPartyInfo>;
}

interface IConnectCamRequest {
	babyUid: string;
	cameraUid: string;
	momUid: string;
}

interface IGetCamListResponse extends IApiResponse {
	period: {
		cameraConnectCnt: number;
		cameraDisConnectCnt: number;
		cameraList: Array<ICamListData>;
		viewAble: string;
	};
}

interface IGetCamDataResponse extends IApiResponse {
	period: ICamInfoData;
}

interface IConnectCamResponse extends IApiResponse {
	no: number;
	success: TSuccess;
}

interface ICamSnapshot {
	period: {
		cameraImg: string;
	};
}

export interface ICamFamliyListData {
	regGbnCd: string;
	watchingYn: string;
	watchCnt: number;
	hours: number;
	minutes: number;
	seconds: number;
}

interface ICamAnotherPartyResponse extends IApiResponse {
	period: {
		partyYn: string;
	};
}

export const getCamSnapshot = (cameraUid: string) => {
	return new Promise((resolve: (response: ICamSnapshot) => void, reject: (e: Error) => void) => {
		$http({
			url: 'cameraSnapshot',
			method: 'GET',
			params: { cameraUid },
		})(true)
			.then((response) => resolve(response.data))
			.catch((e) => {
				const { response } = e;
				if (!response) {
					reject(e);
				} else {
					reject(response.data);
				}
			});
	});
};

export const getCamList = () => {
	return new Promise((resolve: (response: IGetCamListResponse) => void, reject: (e: Error) => void) => {
		$http({
			url: '/cameraList',
			method: 'GET',
		})(true)
			.then((response) => resolve(response.data))
			.catch((e) => {
				const { response } = e;
				if (!response) {
					reject(e);
				} else {
					reject(response.data);
				}
			});
	});
};

export const getCamData = (cameraUid: string) => {
	return new Promise((resolve: (response: IGetCamDataResponse) => void, reject: (e: Error) => void) => {
		$http({
			url: '/cameraInfo',
			method: 'GET',
			params: { cameraUid },
		})(true)
			.then((response) => resolve(response.data))
			.catch((e) => {
				const { response } = e;
				if (!response) {
					reject(e);
				} else {
					reject(response.data);
				}
			});
	});
};

// 2023-05-09 휴대폰번호로 기존 연결 여부 체크
export const getAnotherParty = (phone: string) => {
	return new Promise((resolve: (response: ICamAnotherPartyResponse) => void, reject: (e: Error) => void) => {
		$http({
			url: '/anotherParty',
			method: 'GET',
			params: { phone },
		})(true)
			.then((response) => resolve(response.data))
			.catch((e) => {
				const { response } = e;
				if (!response) {
					reject(e);
				} else {
					reject(response.data);
				}
			});
	});
};

export const connectCam = (request: IConnectCamRequest) => {
	return new Promise((resolve: (response: IConnectCamResponse) => void, reject: (e: Error) => void) => {
		$http({
			url: '/cameraRegister',
			method: 'POST',
			data: request,
		})(true)
			.then((response) => resolve(response.data))
			.catch((e) => {
				const { response } = e;
				if (!response) {
					reject(e);
				} else {
					reject(response.data);
				}
			});
	});
};

export const disconnectCam = (cameraUid: string, momCheckin: string) => {
	return new Promise((resolve: (response: IConnectCamResponse) => void, reject: (e: Error) => void) => {
		$http({
			url: '/cameraDisconnect',
			method: 'PUT',
			data: { cameraUid, momCheckin },
		})(true)
			.then((response) => resolve(response.data))
			.catch((e) => {
				const { response } = e;
				if (!response) {
					reject(e);
				} else {
					reject(response.data);
				}
			});
	});
};

export interface ICheckinMomList {
	customerUid: string;
	customerName: string;
	connect: 'Y' | 'N';
}

interface IGetCustomerCheckinListResponse extends IApiResponse {
	period: Array<ICheckinMomList>;
}

export interface ICustomerBabyList {
	babyUid: string;
	babyName: string;
	connect: 'Y' | 'N';
}

interface IGetCustomerBabyListResponse extends IApiResponse {
	period: Array<ICustomerBabyList>;
}

export const getCustomerCheckinList = () => {
	return new Promise((resolve: (response: IGetCustomerCheckinListResponse) => void, reject: (e: Error) => void) => {
		$http({
			url: '/customerCheckinList',
			method: 'GET',
		})(true)
			.then((response) => resolve(response.data))
			.catch((e) => {
				const { response } = e;
				if (!response) {
					reject(e);
				} else {
					reject(response.data);
				}
			});
	});
};

export const getCustomerBabyList = (momUid: string) => {
	return new Promise((resolve: (response: IGetCustomerBabyListResponse) => void, reject: (e: Error) => void) => {
		$http({
			url: '/customerBabyList',
			method: 'GET',
			params: { momUid },
		})(true)
			.then((response) => resolve(response.data))
			.catch((e) => {
				const { response } = e;
				if (!response) {
					reject(e);
				} else {
					reject(response.data);
				}
			});
	});
};
