import { message } from "antd";

export const careToast = (msg: string) => {
  message.info(msg);
  const antMessage = document.querySelector(".ant-message");
  if (antMessage) {
    antMessage.classList.add("jellybook-toast");
  }
};

export default careToast;
