// @ts-nocheck
//REACT
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
//STYLE
import styled from 'styled-components';
import { Radio } from 'antd';
import { JellyDialog, JellyButton, JellyToast, JellyAlert } from '../../components';

//API
import { addCustomerInfo, getCustomerInfoDetail, modifyCustomerInfoDetail, deleteCustomer } from '../../api';

//TYPE
import { ICustomerInfo } from '../../api/customer';
import { RadioChangeEvent } from 'antd/lib/radio';

//CONTEXT
import { useCustomerInfo } from '../../contexts';

//UTIL
import { httpError, autoHyphen, isBirthDay } from '../../services';
import moment from 'moment/moment';
import { log } from 'console';

interface IDialogCustomer {
	//visible: boolean;
	[key: string]: any;
}

interface IRequiredStatus {
	name: boolean;
	birth: boolean;
	phone: boolean;
	baby1: boolean;
	babyGender1: boolean;
	baby2: boolean;
	babyGender2: boolean;
	baby3: boolean;
	babyGender3: boolean;
	baby4: boolean;
	babyGender4: boolean;
}

const defaultCutomerInfoState: ICustomerInfo = {
	name: '',
	phone: '',
	birth: '',
	baby1: '',
	babyGender1: '1',
	baby2: '',
	babyGender2: '1',
	baby3: '',
	babyGender3: '1',
	baby4: '',
	babyGender4: '1',
	checkin: 'Y',
};

const defaultRequireState: IRequiredStatus = {
	name: true,
	phone: true,
	birth: true,
	baby1: true,
	babyGender1: true,
	baby2: true,
	babyGender2: true,
	baby3: true,
	babyGender3: true,
	baby4: true,
	babyGender4: true,
};

const DialogCustomer: React.FC<IDialogCustomer> = ({ ...props }) => {
	const babyPrefixes = ['첫', '둘', '셋', '넷'];
	const history = useHistory();
	const {
		state: { visible, customerUid },
		dispatch,
	} = useCustomerInfo();
	const [customerInfo, setCustomerInfo] = useState<ICustomerInfo>(defaultCutomerInfoState);
	const [requiredStatus, setRequiredStatus] = useState<IRequiredStatus>(defaultRequireState);
	const [babies, setBabies] = useState<number[]>([0]);
	const isNill = (str: any) => {
		if (typeof str === 'string') {
			return str === '';
		} else {
			return !str;
		}
	};

	const validateInfo = () => {
		const missingValidList = [];
		if (customerInfo.name.trim().length === 0) {
			missingValidList.push('name');
		}
		if (customerInfo.phone.trim().length === 0) {
			missingValidList.push('phone');
		}
		if (customerInfo.birth.trim().length < 10 || customerInfo.birth.trim().length === 0 || !isBirthDay(customerInfo.birth)) {
			missingValidList.push('birth');
		}

		if (customerInfo.baby1.trim().length === 0) {
			missingValidList.push('baby1');
		}

		if (!customerInfo.babyGender1 || customerInfo.babyGender1.trim().length === 0) {
			missingValidList.push('babyGender1');
		}

		// 아기이름 특수문자 체크
		// eslint-disable-next-line
		const reg = /[^\w\sㄱ-힣]|[\_]/g;

		if (reg.test(customerInfo.baby1)) {
			missingValidList.push('baby1');
		}

		babyPrefixes.forEach((_, index) => {
			if (
				index !== 0 &&
				!(isNill(customerInfo[`baby${index}`]) || isNill(customerInfo[`babyGender${index}`])) &&
				!isNill(customerInfo[`baby${index + 1}`])
			) {
				if (!customerInfo[`babyGender${index + 1}`] || customerInfo[`babyGender${index + 1}`].trim().length === 0) {
					missingValidList.push(`babyGender${index + 1}`);
				}
			}
			if (
				index !== 0 &&
				!(isNill(customerInfo[`baby${index}`]) || isNill(customerInfo[`babyGender${index}`])) &&
				reg.test(customerInfo[`baby${index}`])
			) {
				missingValidList.push(`baby${index + 1}`);
			}
		});

		if (missingValidList.length > 0) {
			throw new Error(missingValidList.join(','));
		}
	};

	const handleValidateError = (e: Error) => {
		const { message } = e;
		const errorList = message.split(',');
		const errorObj: any = {};
		errorList.forEach((key: string) => {
			errorObj[key] = false;
		});

		setRequiredStatus({
			...defaultRequireState,
			...errorObj,
		});
	};

	const handleChangeInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
		const MAX_LENGTH = {
			PHONE: 13,
			BIRTH: 10,
		};
		if (e.currentTarget.name === 'phone') {
			if (e.currentTarget.value.length <= MAX_LENGTH.PHONE) {
				setCustomerInfo({
					...customerInfo,
					[e.currentTarget.name]: autoHyphen(e.currentTarget.value, 'PHONE'),
				});
			} else {
				return false;
			}
		} else if (e.currentTarget.name === 'birth') {
			if (e.currentTarget.value.length <= MAX_LENGTH.BIRTH) {
				setCustomerInfo({
					...customerInfo,
					[e.currentTarget.name]: autoHyphen(e.currentTarget.value, 'BIRTH'),
				});
			} else {
				return false;
			}
		} else {
			setCustomerInfo({
				...customerInfo,
				[e.currentTarget.name]: e.currentTarget.value,
			});
		}
	};
	const handleChangeInfoRadio = (e: RadioChangeEvent) => {
		if (customerUid && e.target.value === 'N') {
			JellyAlert.confirm({
				content: (
					<p>
						미입실로 변경 하시겠습니까?
						<br />
						연결된 카메라가 있을 경우
						<br />
						자동으로 연결이 해지됩니다.
					</p>
				),
				ok() {
					setCustomerInfo({
						...customerInfo,
						checkin: e.target.value,
					});
				},
			});
		} else {
			setCustomerInfo({
				...customerInfo,
				checkin: e.target.value,
			});
		}
	};

	const handleChangeGenderRadio = (e: RadioChangeEvent) => {
		console.log('e.target.value : ', e.target.value);
		setCustomerInfo({
			...customerInfo,
			// @ts-ignore
			[e.target.name]: e.target.value,
		});
	};

	//TODO: 등록 / 수정 함수 하나로 사용
	const handleAddCustomer = async () => {
		try {
			validateInfo();
			const { success } = await addCustomerInfo(customerInfo);
			if (success === 1) {
				JellyToast('등록되었습니다.');
				dispatch({ type: 'HIDE' });
				dispatch({ type: 'REFERSH' });
				resetDialog();
			}
		} catch (error) {
			if (error.code) {
				if (error.errorParam == 'phoneDuplicate') {
					JellyToast(error.message);
				} else if (error.errorParam == 'phone') {
					JellyToast(error.message);
				} else {
					httpError(history, error.code);
				}
			} else {
				handleValidateError(error);
			}
		}
	};

	const handleGetCustomerDetail = useCallback(async () => {
		try {
			const { period } = await getCustomerInfoDetail({
				customerUid,
			});

			for (const [key, value] of Object.entries(period)) {
				if (key.startsWith('babyGender') && isNill(value)) {
					period[key] = '1';
				}
			}
			setCustomerInfo({ ...customerInfo, ...period });
			setBabies([period.baby1, period.baby2, period.baby3, period.baby4].filter((baby) => !isNill(baby)).map((_, index) => index));
		} catch ({ code }) {
			httpError(history, code);
		}
	}, [customerUid, history]);

	const handleModifyCustomerDetail = async () => {
		try {
			validateInfo();
			const { success } = await modifyCustomerInfoDetail({
				...customerInfo,
				customerUid,
			});

			if (success === 1) {
				if (customerUid) {
					JellyDialog.info({
						title: '수정',
						content: '수정되었습니다.',
					});
					resetDialog();
					// dispatch({
					//   type: "MODIFY_SUCCESS",
					//   uid: customerUid,
					//   modified: { ...customerInfo }
					// });
					dispatch({ type: 'REFERSH' });
				}
			} else {
				alert('실패!');
			}
		} catch (error) {
			if (error.code) {
				if (error.errorParam == 'phoneDuplicate') {
					//careToast("이미 등록된 연락처입니다.");
					JellyDialog.info({
						title: '수정',
						content: error.message,
					});
				} else {
					httpError(history, error.code);
				}
			} else {
				handleValidateError(error);
			}
		}
	};

	const handleDeleteCustomer = async () => {
		if (customerUid) {
			try {
				const { success } = await deleteCustomer({
					customerUid,
				});
				if (success === 1) {
					JellyDialog.info({
						title: '삭제',
						content: '삭제되었습니다.',
					});
					dispatch({ type: 'REFERSH' });
					resetDialog();
				}
			} catch ({ code }) {
				httpError(history, code);
			}
		}
	};
	const handleCloseDialog = () => {
		dispatch({
			type: 'HIDE',
		});
		resetDialog();
	};

	const handleMoreBaby = () => {
		if (babies.length >= 4) {
			alert('최대 4명까지 등록 가능합니다');
		} else {
			setBabies([...babies, babies[babies.length - 1] + 1]);
		}
	};

	const resetDialog = () => {
		setCustomerInfo(defaultCutomerInfoState);
		setBabies([0]);
		setRequiredStatus(defaultRequireState);
	};

	useEffect(() => {
		if (customerUid) {
			handleGetCustomerDetail();
		}
	}, [handleGetCustomerDetail, customerUid]);

	useEffect(() => {
		console.log('customerInfo : ', customerInfo);
	}, [customerInfo]);

	return (
		<JellyDialog
			{...props}
			buttons={
				!customerUid
					? [
							<JellyButton key={4} onClick={handleMoreBaby}>
								아기추가
							</JellyButton>,
							<JellyButton key={1} onClick={handleAddCustomer}>
								저장
							</JellyButton>,
					  ]
					: [
							<JellyButton key={5} onClick={handleMoreBaby}>
								아기추가
							</JellyButton>,
							<JellyButton
								key={2}
								onClick={() => {
									JellyAlert.confirm({
										content: '삭제하시겠습니까?',
										ok() {
											handleDeleteCustomer();
										},
									});
								}}
							>
								삭제
							</JellyButton>,
							<JellyButton bgType="jellybook" key={3} onClick={handleModifyCustomerDetail}>
								수정
							</JellyButton>,
					  ]
			}
			visible={visible}
			onPressClose={handleCloseDialog}
			title="고객정보"
		>
			<CustomerNoticeWrap>
				젤리캠은 계정공유가 불가능하여 아빠 정보로 고객 등록을 하면 산모님의 캠 시청이 불가능합니다. 반드시 산모님의 정보로 고객등록을 진행해주세요.
			</CustomerNoticeWrap>
			<CustomerInfoForm>
				<CustomerInfoFormItem>
					<CustomerInfoLabel>입실여부</CustomerInfoLabel>
					<CustomerInfoWrap>
						<Radio.Group name="checkin" onChange={handleChangeInfoRadio} value={customerInfo.checkin}>
							<Radio value="Y">입실</Radio>
							<Radio value="N">미입실</Radio>
						</Radio.Group>
					</CustomerInfoWrap>
				</CustomerInfoFormItem>
				<CustomerInfoFormItem>
					<CustomerInfoLabel className="required">고객명</CustomerInfoLabel>
					<CustomerInfoWrap>
						<CustomerInfoInput
							name="name"
							type="text"
							autoComplete={'off'}
							onChange={handleChangeInfo}
							placeholder="고객명을 입력해주세요."
							value={customerInfo.name || ''}
						/>
						{!requiredStatus.name && <CustomerErrorMessage>이름을 입력해주세요.</CustomerErrorMessage>}
					</CustomerInfoWrap>
				</CustomerInfoFormItem>
				<CustomerInfoFormItem>
					<CustomerInfoLabel className="required">연락처</CustomerInfoLabel>
					<CustomerInfoWrap>
						<CustomerInfoInput onChange={handleChangeInfo} name="phone" type="text" placeholder="010-1234-1234" value={customerInfo.phone || ''} />
						{!requiredStatus.phone && <CustomerErrorMessage>연락처를 입력해주세요.</CustomerErrorMessage>}
					</CustomerInfoWrap>
				</CustomerInfoFormItem>
				<CustomerInfoFormItem>
					<CustomerInfoLabel className="required">생년월일</CustomerInfoLabel>
					<CustomerInfoWrap>
						<CustomerInfoInput onChange={handleChangeInfo} name="birth" type="text" placeholder="yyyy-mm-dd" value={customerInfo.birth || ''} />
						{!requiredStatus.birth && <CustomerErrorMessage>생년월일을 확인해주세요.</CustomerErrorMessage>}
					</CustomerInfoWrap>
				</CustomerInfoFormItem>
				{babies.map((index) => {
					return (
						<React.Fragment key={index}>
							<CustomerInfoFormItem>
								<CustomerInfoLabel className={index === 0 ? 'required' : ''}>{`아기이름${index + 1}`}</CustomerInfoLabel>
								<CustomerInfoWrap>
									<CustomerInfoInput
										onChange={handleChangeInfo}
										value={customerInfo[`baby${index + 1}`] || ''}
										name={`baby${index + 1}`}
										type="text"
										placeholder={`${babyPrefixes[index]}째`}
									/>
									{/* @ts-ignore*/}
									{!requiredStatus[`baby${index + 1}`] && <CustomerErrorMessage>아기 이름을 확인해주세요.</CustomerErrorMessage>}
								</CustomerInfoWrap>
							</CustomerInfoFormItem>
							<CustomerInfoFormItem>
								<CustomerInfoLabel className={index === 0 ? 'required' : ''}>{`성별${index + 1}`}</CustomerInfoLabel>
								<CustomerInfoWrap>
									<Radio.Group
										name={`babyGender${index + 1}`}
										onChange={handleChangeGenderRadio}
										value={customerInfo[`babyGender${index + 1}`]}
									>
										<Radio value="1">남자아이</Radio>
										<Radio value="2">여자아이</Radio>
									</Radio.Group>
									{/* @ts-ignore*/}
									{!requiredStatus[`babyGender${index + 1}`] && <CustomerErrorMessage>아기 성별을 확인해주세요.</CustomerErrorMessage>}
								</CustomerInfoWrap>
							</CustomerInfoFormItem>
						</React.Fragment>
					);
				})}
			</CustomerInfoForm>
		</JellyDialog>
	);
};

const CustomerInfoForm = styled.ul``;
const CustomerInfoFormItem = styled.li`
	display: flex;
	align-items: center;
	min-height: 60px;
	padding: 12px 0;
	border-bottom: 1px solid #f6f1fe;

	&:nth-of-type(1) {
		min-height: 42px;
	}
`;
const CustomerInfoLabel = styled.label`
	display: flex;
	flex: 0 0 77px;
	font-size: 12px;
	color: #8b8b8b;
	font-weight: 700;

	&.required {
		&:after {
			content: '';
			display: block;
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background-color: #ec4c4c;
			margin-left: 3px;
		}
	}
`;
const CustomerInfoWrap = styled.div`
	color: #151515;
	flex: 1;
`;

const CustomerErrorMessage = styled.div`
	color: #ec4c4c;
	margin-top: 7px;
	font-size: 12px;

	&:before {
		vertical-align: middle;
		content: '';
		display: inline-block;
		margin-right: 5px;
		width: 3px;
		height: 3px;
		border-radius: 3px;
		background-color: #ec4c4c;
	}
`;

const CustomerInfoInput = styled.input`
	border-radius: 3px;
	border: 1px solid #e6e6e6;
	padding: 0 10px;
	width: 100%;
	height: 36px;

	&::placeholder {
		color: #d5d5d5;
	}
`;

const CustomerNoticeWrap = styled.p`
	color: #ff0000;
	border: 1px solid #ff0000;
	padding: 10px;
	font-weight: bold;
	line-height: 1.45;
`;
export default DialogCustomer;
