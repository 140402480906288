// REACT
import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

// COMPONENT
import DialogChangePassword from "./DialogChangePassword";
import { JellyButton, JellyLoading } from "../../components";

// STYLE
import styled from "styled-components";
import {
  JellyInput,
  JellyContainer,
  JellyContainerTop,
  TableOptionWrap,
  JellyVerticalFormItem,
  JellyFormLabel,
  JellyInfoWrap
} from "../../style";

// API
import { getBranchInfo } from "../../api";

// UTIL
import { httpError, autoHyphen } from "../../utils";
import useAuthStore from "../../store/auth/authStore";

const Config: React.FC = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [passwordDialogVisible, setPasswordDialogVisible] = useState<boolean>(false);
  const [configData, setConfigData] = useState<{ [key: string]: string }>({
    addr: "",
    addrDetail: "",
    branchName: "",
    businessNumber: "",
    mainCheifPhone: "",
    mainChief: ""
  });

  const {pccIndex} = useAuthStore();

  const renderBranchInfo = useCallback(async () => {
    setIsLoading(true);
    try {
      const { period } = await getBranchInfo();
      const data: { [key: string]: string } = {};
      for (let key in period) {
        data[key] = period[key] ? period[key] : "";
      }
      setConfigData(data);
    } catch ({ code }) {
      httpError(history, code);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    renderBranchInfo();
    return () => {};
  }, [renderBranchInfo, pccIndex]);

  return (
    <JellyContainer>
      <JellyLoading visible={isLoading} fill />
      <JellyContainerTop justifyContent="space-between">
        <ConfigTitle>업체정보</ConfigTitle>
        <TableOptionWrap>
          <JellyButton
            shape="round"
            bgType="white"
            title="비밀번호 변경"
            onClick={() => setPasswordDialogVisible(true)}
          >
            비밀번호 변경하기
          </JellyButton>
        </TableOptionWrap>
        <DialogChangePassword visible={passwordDialogVisible} onPressClose={() => setPasswordDialogVisible(false)} />
      </JellyContainerTop>
      <ConfigFormWrap>
        <CompanyInfoForm>
          <JellyVerticalFormItem inline>
            <JellyFormLabel vertical>조리원명</JellyFormLabel>
            <JellyInfoWrap>
              <JellyInput disabled value={configData.branchName} type="text" />
            </JellyInfoWrap>
          </JellyVerticalFormItem>

          <JellyVerticalFormItem inline>
            <JellyFormLabel vertical>사업자번호</JellyFormLabel>
            <JellyInfoWrap>
              <JellyInput disabled value={configData.businessNumber} type="text" />
            </JellyInfoWrap>
          </JellyVerticalFormItem>

          <JellyVerticalFormItem inline>
            <JellyFormLabel vertical>담당자</JellyFormLabel>
            <JellyInfoWrap>
              <JellyInput disabled value={configData.mainChief} type="text" />
            </JellyInfoWrap>
          </JellyVerticalFormItem>

          <JellyVerticalFormItem inline>
            <JellyFormLabel vertical>담당자 연락처</JellyFormLabel>
            <JellyInfoWrap>
              <JellyInput disabled value={autoHyphen(configData.mainCheifPhone, "PHONE")} type="text" />
            </JellyInfoWrap>
          </JellyVerticalFormItem>
        </CompanyInfoForm>
        <CompanyInfoForm>
          <JellyVerticalFormItem inline>
            <JellyFormLabel vertical>주소</JellyFormLabel>
            <JellyInfoWrap>
              <JellyInput disabled value={configData.addr} type="text" />
            </JellyInfoWrap>
          </JellyVerticalFormItem>
          <JellyVerticalFormItem inline>
            <JellyFormLabel vertical>상세주소</JellyFormLabel>
            <JellyInfoWrap>
              <JellyInput disabled value={configData.addrDetail} type="text" />
            </JellyInfoWrap>
          </JellyVerticalFormItem>
        </CompanyInfoForm>
        <BottomText>
          <Message>업체정보 변경을 원할시 고객센터로 연락바랍니다.</Message>
          <ServiceCenter>jellybook 고객센터 070-4616-5990</ServiceCenter>
        </BottomText>
      </ConfigFormWrap>
    </JellyContainer>
  );
};

const ConfigTitle = styled.div`
  color: #151515;
  font-size: 22px;
  font-weight: 900;
  display: flex;
  align-items: center;
  transform: skew(0.05deg);
  margin-right: 25px;
`;

const CompanyInfoForm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0;
  border-bottom: 1px dashed #eee;
  &:first-child {
    padding-top: 0;
  }
`;

const BottomText = styled.div`
  padding-top: 35px;
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const Message = styled.div`
  font-weight: bold;
  padding-right: 10px;
  color: #bababa;
  border-right: 1px solid #eee;
`;

const ServiceCenter = styled.div`
  color: #cacaca;
  padding-left: 10px;
`;

const ConfigFormWrap = styled.div`
  padding: 26px 25px 35px 25px;
`;

export default Config;
