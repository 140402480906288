import { ICustomerInfo } from "../api/customer";
import moment from "moment";
export interface ICustomerState {
  visible: boolean;
  customerUid?: string | null;
  modified?: ICustomerInfo;
  refresh: number;
  downloadVisible: boolean;
  checkin?: "Y" | "N" | "ALL" | null;
  searchVal: string;
}
export type TCustomerInfoActions =
  | {
      type: "ADD";
    }
  | {
      type: "MODIFY";
      uid: string;
    }
  | {
      type: "HIDE";
    }
  | {
      type: "MODIFY_SUCCESS";
      uid: string;
      modified: ICustomerInfo;
    }
  | {
      type: "DOWNLOAD";
      checkin?: "Y" | "N" | "ALL" | null;
      searchVal: string;
    }
  | {
      type: "DOWNLOAD_HIDE";
    }
  | {
      type: "REFERSH";
    };

export const customerInfoInit: ICustomerState = {
  visible: false,
  customerUid: null,
  modified: {
    name: "",
    phone: "",
    birth: "",
    baby1: "",
    baby2: "",
    baby3: "",
    baby4: "",
    checkin: null,
    customerUid: null
  },
  refresh: 0,
  downloadVisible: false,
  searchVal: "",
  checkin: "ALL"
};

export function customerReducer(
  state: ICustomerState = customerInfoInit,
  action: TCustomerInfoActions
): ICustomerState {
  switch (action.type) {
    case "ADD":
      state.visible = true;
      return { ...state };
    case "HIDE":
      state.visible = false;
      state.customerUid = null;
      state.modified = customerInfoInit.modified;
      return { ...state };
    case "MODIFY":
      state.visible = true;
      state.customerUid = action.uid;
      return { ...state };
    case "MODIFY_SUCCESS":
      state.visible = false;
      state.customerUid = null;
      state.modified = action.modified;
      return { ...state };
    case "REFERSH":
      state.visible = false;
      state.customerUid = null;
      state.modified = customerInfoInit.modified;
      state.refresh = moment().unix();
      return { ...state };
    case "DOWNLOAD":
      state.downloadVisible = true;
      state.checkin = action.checkin;
      state.searchVal = action.searchVal;
      return { ...state };
    case "DOWNLOAD_HIDE":
      state.searchVal = "";
      state.downloadVisible = false;
      state.checkin = "ALL";
      return { ...state };
    default:
      return state;
  }
}
