import React, { useEffect, useState } from 'react';
import { JellyAlert } from '../../components';
import styled from 'styled-components';
import moment from 'moment';

const BannerPopup = ({ bannerList, index }) => {
	const [bannerAlert, setBannerAlert] = useState(false);
	console.log(bannerList);
	const { advIdx, imgUrl, linkUrl, text } = bannerList;
	// console.log(advIdx);

	const handleConfirmBannerPopup = () => {
		setBannerAlert(!bannerAlert);
	};

	const handleIgnoreBannerPopup = () => {
		const ignoreStamp = {
			expire: moment().add(1, 'd'),
		};
		handleConfirmBannerPopup();
		localStorage.setItem(`ignoreBannerPopup_${advIdx}`, JSON.stringify(ignoreStamp));
	};

	useEffect(() => {
		if (bannerList) {
			const checkCustomerPopup = () => {
				const isIgnorePopup = localStorage.getItem(`ignoreBannerPopup_${advIdx}`);
				if (!isIgnorePopup) {
					setBannerAlert(true);
				} else {
					const { expire } = JSON.parse(isIgnorePopup);
					if (expire) {
						const duration = moment.duration(moment().diff(expire));
						const hours = duration.asHours();
						if (hours >= 0) {
							setBannerAlert(true);
							localStorage.removeItem(`ignoreBannerPopup_${advIdx}`);
						}
					}
				}
			};

			checkCustomerPopup();
		}
	}, [bannerList]);

	return (
		<>
			{bannerAlert && (
				<JellyAlert
					visible={bannerAlert}
					buttons={[
						{
							render: () => (
								<AlertButton
									key={0}
									onClick={() => {
										handleIgnoreBannerPopup();
									}}
								>
									오늘 다시 보지 않기
								</AlertButton>
							),
						},
						{
							render: () => (
								<AlertButton key={1} onClick={() => handleConfirmBannerPopup()}>
									확인
								</AlertButton>
							),
						},
					]}
					contentStyle={{
						padding: '0px 0 0 0',
					}}
				>
					<CustomerCenterContent
						style={{
							height: imgUrl !== '' ? 'calc(100vh - 150px)' : 'auto',
						}}
					>
						<a
							href={linkUrl}
							target="_blank"
							rel="noopener noreferrer"
							// style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', position: 'relative' }}
						>
							<>
								{imgUrl !== '' && (
									<div
										style={{
											position: 'relative',
											width: '100%',
											// maxHeight: 'calc(100% - 40px)',
											height: text !== '' ? '90%' : '100%',
											fontSize: 0,
										}}
									>
										<CustomerCenterImage src={imgUrl} />
									</div>
								)}
								{text !== '' && <CustomerCenterMessage>{text}</CustomerCenterMessage>}
							</>
						</a>
					</CustomerCenterContent>
				</JellyAlert>
			)}
		</>
	);
};

// banner alert
const AlertButton = styled.button`
	height: 63px;
	font-size: 14px;
	font-weight: bold;
	border-right: 1px solid #eeeeee;
	background-color: #fff;
	&:nth-last-of-type(1) {
		border-right: none;
	}
`;
const CustomerCenterContent = styled.article`
	min-width: 400px;
	/* height: auto; */
	position: relative;
	overflow-y: auto;
	/* transform: skew(0.04deg); */
	&::-webkit-scrollbar {
		display: none;
	}
`;
const CustomerCenterMessage = styled.p`
	font-size: 20px;
	color: #333;
	line-height: 1.45;
	margin-bottom: 10px;
	white-space: pre-wrap;
	padding: 20px;
`;
const CustomerCenterImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
`;

export default BannerPopup;
