import { $http, IApiResponse, TSuccess } from "../../services";

export interface IBranchInfo {
  [addr: string]: string;
  addrDetail: string;
  branchName: string;
  businessNumber: string;
  mainCheifPhone: string;
  mainChief: string;
}

interface IGetBranchInfoResponse extends IApiResponse {
  period: IBranchInfo;
}

interface ISuccessResponse extends IApiResponse {
  no: number;
  success: TSuccess;
}

interface IUpdatePasswordRequest {
  bfPasswd: string;
  passwd: string;
}

export const getBranchInfo = () => {
  return new Promise((resolve: (response: IGetBranchInfoResponse) => void, reject: (e: Error) => void) => {
    $http({
      url: "/branchInfo",
      method: "GET"
    })(true)
      .then(response => resolve(response.data))
      .catch(e => {
        const { response } = e;
        if (!response) {
          reject(e);
        } else {
          reject(response.data);
        }
      });
  });
};

export const updateBranchInfo = (request: IBranchInfo) => {
  return new Promise((resolve: (response: ISuccessResponse) => void, reject: (e: Error) => void) => {
    $http({
      url: "/branchUpdate",
      method: "PUT",
      data: request
    })(true)
      .then(response => resolve(response.data))
      .catch(e => {
        const { response } = e;
        if (!response) {
          reject(e);
        } else {
          reject(response.data);
        }
      });
  });
};

export const updatePassword = (request: IUpdatePasswordRequest) => {
  return new Promise((resolve: (response: ISuccessResponse) => void, reject: (e: Error) => void) => {
    $http({
      url: "/managerUpdate",
      method: "PUT",
      data: request
    })(true)
      .then(response => resolve(response.data))
      .catch(e => {
        const { response } = e;
        if (!response) {
          reject(e);
        } else {
          reject(response.data);
        }
      });
  });
};
