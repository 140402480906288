import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

/**
 * @author 고종현
 * @version 0.0.1(2022.05.31)
 */

/**
 * 커스텀 스토어 생성
 * @param initialState  스토어 기본값 객체
 * @param storeName redux-dev-tools에서 검색할 스토어의 이름
 */
export const storeInitializer = (initialState : any, storeName : string) =>
	create(
			devtools(
				immer(() => initialState),
				{ name: storeName },
			),
	);

/**
 * @description action type 명이 추가 되는 커스텀  setter를 만들어 주는 함수
 * @param useStore
 */
export const customActionInitializer = (useStore : any) => ({
	//zustand getter
	getState: useStore.getState,

	/**
	 * zustand custom setter
	 * @param stateChanger  전역 상태 변경 함수
	 * @param actionType 발생할 액션의 이름
	 * @returns {*}
	 */
	setState: (stateChanger : any, actionType = 'ANONYMOUS') => useStore.setState(stateChanger, false, actionType),
});
