//REACT
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// API
import { updatePassword } from "../../api";

// STYLE
import styled from "styled-components";
import { CareAlert, CareButton, careToast } from "../../style/jellybook-component";
import {
  JellyContainer,
  JellyContainerTop,
  JellyInput,
  JellyVerticalFormItem,
  JellyFormLabel,
  JellyInfoWrap
} from "../../style";

// UTIL
import { httpError } from "../../utils";

interface IPasswordData {
  current: string;
  new: string;
  confirm: string;
}

const ChangePassword: React.FC = () => {
  const history = useHistory();
  const [password, setPassword] = useState<IPasswordData>({
    current: "",
    new: "",
    confirm: ""
  });

  const MESSAGE = {
    EMPTY: {
      CURRENT: "현재 비밀번호를 입력해주세요.",
      NEW: "새 비밀번호를 입력해주세요."
    },
    INCORRECT: {
      CURRENT: "현재 비밀번호가 일치하지 않습니다.",
      REGEX: "8~16자 영문, 숫자를 사용해주세요.",
      CONFIRM: "새 비밀번호가 일치하지 않습니다."
    }
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    //   setRequiredStatus({ current: true, new: true, confirm: true }); // INIT STATE
    setPassword({
      ...password,
      [e.target.name]: e.target.value
    });
  };

  const handleClickSubmit = async () => {
    const passwordRegex = /^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z]).*$/g;
    // setRequiredStatus({ current: true, new: true, confirm: true }); // INIT STATE

    if (password.current.length <= 0) {
      CareAlert.info({
        content: MESSAGE.EMPTY.CURRENT
      });
    } else if (password.new.length <= 0) {
      CareAlert.info({
        content: MESSAGE.EMPTY.NEW
      });
    } else if (password.confirm.length <= 0) {
      CareAlert.info({
        content: MESSAGE.EMPTY.NEW
      });
    } else if (!password.new.match(passwordRegex)) {
      CareAlert.info({
        content: MESSAGE.INCORRECT.REGEX
      });
    } else if (password.new !== password.confirm) {
      CareAlert.info({
        content: MESSAGE.INCORRECT.CONFIRM
      });
    } else {
      try {
        const { success } = await updatePassword({ bfPasswd: password.current, passwd: password.new });
        if (success === 1) {
          careToast("비밀번호 변경이 완료되었습니다.");
          history.push("/");
        }
      } catch ({ code, errorParam }) {
        if (errorParam === "mismatch") {
          CareAlert.info({
            content: MESSAGE.INCORRECT.CURRENT
          });
        } else {
          httpError(history, code);
        }
      }
    }
  };

  return (
    <JellyContainer>
      <JellyContainerTop justifyContent="center">
        <Title>비밀번호 변경하기</Title>
      </JellyContainerTop>
      <ChangePasswordWrap>
        <ContentTitle>지금, 비밀번호를 변경해 주세요.</ContentTitle>
        <ContentDescription>
          회원님은 6개월 이상 비밀번호를 변경하지 않으셨습니다. 소중한 개인정보 보호를 위해 비밀번호를 변경해 주세요.
          <br />
          비밀번호는 주기적으로 변경하고, 숫자, 영문, 특수문자를 혼합하여 설정하면 개인정보를 더 안전하게 보호할 수
          있습니다.
        </ContentDescription>
        <PasswordForm>
          <JellyVerticalFormItem>
            <JellyFormLabel vertical>현재 비밀번호</JellyFormLabel>
            <JellyInfoWrap>
              <JellyInput
                placeholder="현재 비밀번호를 입력해주세요."
                value={password.current}
                name="current"
                onChange={handleChangePassword}
                type="password"
              />
              {/* {!requiredStatus.current && <JellyFormErrorMessage>{errorMsg.current}</JellyFormErrorMessage>} */}
            </JellyInfoWrap>
          </JellyVerticalFormItem>
          <JellyVerticalFormItem>
            <JellyFormLabel vertical>변경 비밀번호</JellyFormLabel>
            <JellyInfoWrap>
              <JellyInput
                placeholder="8~16 (영문, 숫자 조합)"
                value={password.new}
                name="new"
                onChange={handleChangePassword}
                type="password"
              />
              {/* {!requiredStatus.new && <JellyFormErrorMessage>{errorMsg.new}</JellyFormErrorMessage>} */}
            </JellyInfoWrap>
          </JellyVerticalFormItem>
          <JellyVerticalFormItem style={{ marginBottom: 0 }}>
            <JellyFormLabel vertical>비밀번호 확인</JellyFormLabel>
            <JellyInfoWrap>
              <JellyInput
                placeholder="8~16 (영문, 숫자 조합)"
                value={password.confirm}
                name="confirm"
                onChange={handleChangePassword}
                type="password"
              />
              {/* {!requiredStatus.confirm && <JellyFormErrorMessage>{errorMsg.confirm}</JellyFormErrorMessage>} */}
            </JellyInfoWrap>
          </JellyVerticalFormItem>
          <FormButtonWrap>
            <SkipButton style={{ width: "100%" }} bgType="white" onClick={() => history.push("/")}>
              다음에 변경하기
            </SkipButton>
            <CareButton style={{ width: "100%" }} bgType="jellybook" onClick={handleClickSubmit}>
              지금 변경하기
            </CareButton>
          </FormButtonWrap>
        </PasswordForm>
      </ChangePasswordWrap>
    </JellyContainer>
  );
};

const ContentTitle = styled.p`
  text-align: center;
  color: #151515;
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 15px;
  transform: skew(0.05deg);
`;
const ContentDescription = styled.p`
  text-align: center;
  color: #8b8b8b;
  font-size: 13px;
  line-height: 1.54;
  margin-bottom: 35px;
  transform: skew(0.05deg);
`;

const Title = styled.div`
  font-size: 22px;
  color: #151515;
  font-weight: 900;
  text-align: center;
  transform: skew(0.05deg);
`;

const ChangePasswordWrap = styled.div`
  width: 635px;
  margin: 0 auto;
  padding: 50px 0;
`;

const PasswordForm = styled.div`
  width: 290px;
  margin: 0 auto;
  transform: skew(0.05deg);
`;

const SkipButton = styled(CareButton)`
  border-color: #eee;
  color: #bababa;
  margin-right: 10px;
`;

const FormButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 38px;
`;

export default ChangePassword;
