import { $http, $httpForMaster, IApiResponse, TSuccess } from '../../services';

export interface ILoginRequest {
	id: string;
	pass: string;
}
interface ILoginResponsePeriod {
	success: TSuccess;
	accessToken: string;
	lockPd: number;
	masterYn: string;
	ourHomeYn: string;
	branchList: any;
}
interface ILoginResponse extends IApiResponse {
	period: ILoginResponsePeriod;
}
export const login = (request: ILoginRequest) => {
	return new Promise((resolve: (response: ILoginResponse) => void, reject: (e: Error) => void) => {
		$http({
			url: '/login',
			baseURL: process.env.REACT_APP_API_URL_WITHOUT_API,
			method: 'POST',
			data: request,
		})(false)
			.then((response) => resolve(response.data))
			.catch((e) => {
				const { response } = e;
				if (!response) {
					reject(e);
				} else {
					reject(response.data);
				}
			});
	});
};

export const getMasterOptionAPI = () => {
	return new Promise((resolve: (response: any) => void, reject: (e: Error) => void) => {
		$httpForMaster({
			url: '/masterCheck',
			baseURL: process.env.REACT_APP_API_URL,
			method: 'GET',
		})(true)
			.then((response) => resolve(response.data))
			.catch((e) => {
				const { response } = e;
				if (!response) {
					reject(e);
				} else {
					reject(response.data);
				}
			});
	});
};
